.modal {
  &-open {
    .modal {
      overflow: hidden;
    }
  }
  &.popup {
    .modal-dialog {
      max-width: 490px;
      width: 100%;
      display: flex;
      border-radius: 4px;
      text-align: left;
      align-items: center;
      justify-content: center;
      height: 100vh;
      margin: auto;
      .modal-content {
        padding: 30px 20px;
        border: none;
        .modal-header {
          border: none;
          .modal-title {
            font-size: 18px;
          }
        }
        .modal-body {
          font-size: 14px;
          padding-right: 0;
          &_content {
            max-height: 60vh;
            overflow: auto;
            padding-right: 1rem;
          }
        }
        .modal-footer {
          border: none;
          justify-content: center;
          margin-top: 15px;
          .btn_wrapper {
            &:last-child {
              margin-left: 20px;
            }
            .btn {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  &:not(.popup) {
    .modal-dialog {
      max-width: 960px;
      width: 100%;
      display: flex;
      padding: 0 15px;
      align-items: center;
      justify-content: center;
      height: 100vh;
      margin: auto;
      .modal-header {
        border: none;
        padding: 0;
        .close {
          padding: 0;
          margin: 0;
          outline: none;
          font-size: 30px;
          font-weight: 100;
          position: absolute;
          right: 25px;
          z-index: 9;
          box-shadow: none;
          top: 35px;
          color: var(--color-primary-text);
          opacity: 1;
          display: flex;
          align-items: center;
          background: var(--color-background-01);
          span {
            &.close-text {
              font-size: 14px;
              margin: 0 10px;
            }
          }
        }
      }
      .modal-body {
        font-size: 14px;
        padding: 50px 30px;
        height: 100%;
        &_content {
          max-height: calc(100vh - 100px);
          padding: 0 90px 0 30px;
          overflow-x: auto;
          height: 100%;
          img {
            max-width: 100%;
            margin: 10px 0 20px;
          }
          a{
            text-decoration: underline;
          }
          > p {
            white-space: pre-line;
            &.title {
              margin-bottom: 20px;
              font-size: 18px;
              i {
                margin-right: 10px;
              }
            }
            &.underline {
              border-bottom: 1px solid var(--color-line-01);
              margin: 25px 0 10px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    padding: 0;
    &.popup {
      padding: 30px 15px;
      .modal-dialog {
        margin: 0;
        .modal-content {
          padding: 15px 10px;
          .modal-body {
            font-size: 14px;
          }
          .modal-footer {
            padding: 0;
            .btn_wrapper {
              &:last-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
    &:not(.popup) {
      .modal-dialog {
        padding: 30px 15px 60px;
        margin: 0;
        max-height: 90vh;
        align-items: normal;
        .modal-header {
          .close {
            right: calc(50% - 43px);
            bottom: -35px;
            top: auto;
            color: var(--color-white);
            background: transparent;
            P {
              order: 2;
            }
          }
        }
        .modal-body {
          font-size: 12px;
          padding: 35px 5px 60px;
          &_content {
            padding: 0 15px;
            max-height: calc(90vh - 115px);
          }
        }
      }
    }
    .form_wrapper {
      padding: 0 !important;
    }
  }
}
