@import "mixins";
.search_wrapper {
  padding: 20px 15px;
  box-shadow: 0 0 10px -5px var(--color-black);
  border-radius: 8px;
  width: calc(100% - 36px);
  margin: auto;
  .input-container {
    padding: 0 5px;
    flex-wrap: nowrap;
    .btn_wrapper {
      .btn {
        margin-bottom: 0;
      }
    }
    .search-container {
      position: relative;
      width: 100%;
      margin-right: 20px;
      .ui-autocomplete {
        &.ui-menu {
          position: absolute !important;
          left: 0 !important;
          width: 100% !important;
          background: var(--color-background-01);
          border: 1px solid var(--color-primary);
          border-top: none;
          margin-top: -5px;
          top: auto !important;
          border-radius: 0 0 4px 4px;
          li {
            position: relative;
            &:first-child {
              &:before {
                content: "";
                background-color: var(--color-secondary-text);
                width: calc(100% - 30px);
                height: 1px;
                position: absolute;
                left: 15px;
                top: 0;
              }
            }
            a {
              padding: 12px 15px;
              display: block;
            }
          }
        }
      }
      input {
        width: 100%;
        padding: 11px 15px;
        font-size: 16px;
      }
    }
  }
  .search-result {
    table {
      margin-top: 16px;
      border-collapse: separate;
      border-spacing: 0 7px;
      tbody {
        th {
          @extend %fontNotoSans;
          padding: 5px 15px 10px;

          &.no-search-result-header {
            width: 36.2%;
          }
        }
        tr {
          td {
            padding: 22px 15px;
            border: 1px solid var(--color-line-01);
            font-size: 16px;
            cursor: pointer;
            &:first-child {
              border-radius: 4px 0 0 4px;
              border-right: none;
              color: var(--color-primary);
              font-weight: 700;
              width: 300px;
            }
            &:last-child {
              border-radius: 0 4px 4px 0;
              border-left: none;
            }
          }
          td.no-result-found {
            font-weight: 700;
            font-size: 16px;
            display: table-cell;
            color: var(--color-black);
            border: none;
            width: 100%;
            margin: 0;
            text-align: center;
            border-top: 1px solid var(--color-line-01);
            cursor: unset;
          }
        }
      }
    }
    .no-result-found {
      text-align: center;
      width: 100%;
      font-weight: 700;
      font-size: 16px;
      margin-top: 20px;
      display: block;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 20px 15px 0;
    width: 100%;
    .input-container {
      padding: 0;
    }
    .input-container {
      .search-container {
        margin: 0;
      }
      .btn_wrapper {
        display: none;
      }
    }
    .search-result {
      .no-result-found {
        text-align: left;
        font-size: 12px;
        margin-top: 0;
        padding: 20px 12px;
      }
      table {
        tbody {
          tr {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
            &:first-child {
              display: none;
            }
            td {
              padding: 16px;
              width: 100%;
              font-size: 14px;
              &:first-child {
                border: 1px solid var(--color-line-01);
                border-bottom: none;
                border-radius: 4px 4px 0 0;
                padding-bottom: 8px;
                width: 100%;
              }
              &:last-child {
                padding-top: 0;
                border: 1px solid var(--color-line-01);
                border-top: none;
                border-radius: 0 0 4px 4px;
              }
            }
          }
        }
      }
    }
  }
}

.ui-helper-hidden-accessible {
  display: none !important;
}
