@import "mixins";
.tag {
  width: 100%;
  > span {
    padding: 2px 15px;
    display: inline-block;
    background-color: var(--color-background-02);
    font-size: 12px;
    border-radius: 5px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto !important;
    margin: 0 5px 0 0 !important;
  }
  &.yellow,
  &.blue,
  &.gray {
    width: auto;
    &.with-icon {
      position: absolute;
      width: auto;
    }
    span {
      padding: 6px 15px;
      font-weight: 700;
      font-size: 12px;
      background: var(--color-accent-01);
      border-radius: 35px;
      display: -webkit-box;
    }
  }
  &.with-icon {
    width: auto;
    span {
      position: relative;
      display: -webkit-box;
      img {
        margin-right: 2px;
        vertical-align: middle;
        padding-top: 4px;
        position: relative;
        display: inline-block;
      }
    }
  }
}
