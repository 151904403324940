@import "mixins";
.page-top{
  position: fixed;
  bottom: 60px;
  right: 64px;
  z-index: 9;
  
  .arrow-up{
    width: 56px;
    height: 56px;
    background: var(--color-background-03);
    border-radius: 50%;
    @extend %flexbox-center;
    
  }

  @media screen and (max-width: 640px) {
    right: 16px;
    bottom: 75px;
  }
}