@import 'mixins';
.column-wrapper {
  display: flex;
  flex-wrap: wrap;
  .column_half {
    width: calc(50% - 10px);
    &:nth-child(2n) {
      margin-left: 20px;
    }
  }
  .column_third {
    width: calc(33.33% - 14px);
    margin: 0 0 20px;
    &:nth-child(3n-1) {
      margin: 0 20px 20px 20px;
    }
  }
  @media screen and (max-width: 640px) {
    &.btn_simple-column {
      .middle {
        .btn_simple_wrapper {
          width: calc(50% - 5px);
          margin: 0 10px 10px 0;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
    .column_half {
      width: 100%;
      &:nth-child(2n) {
        margin-left: 0;
      }
    }

    .column_third {
      width: calc(50% - 10px);
      margin: 0 20px 20px 0;
      &:nth-child(2n) {
        margin: 0 0 20px;
      }
    }
  }
}

.article_contents {
  flex-wrap: wrap;
  width: 960px;
  margin: 60px auto 0;
  display: flex;
  max-width: 100%;
  @extend %fontNotoSans;
  font-weight: 500;
  &:last-of-type{
    margin-bottom: 40px;
  }
  .title_wrapper {
    margin: 0 auto;
    background: none;
    color: var(--color-primary-text);
    min-height: auto;
    padding: 30px 15px;
  }
  &-btn {
    max-width: 632px;
    margin: auto;
    .column-wrapper {
      margin: 0 auto 20px;
    }
    .btn_rounded_wrapper {
      margin: auto;
      text-align: center;
    }
  }
  p,
  li {
    font-size: 18px;
    line-height: 27px;
    width: 100%;
    a {
      text-decoration: underline;
      &.right {
        display: block;
        text-align: right;
        color: var(--color-primary);
        &:hover {
          color: var(--color-orange);
        }
      }
    }
  }
  ul {
    padding-inline-start: 20px;
    li {
      list-style: none;
      margin-bottom: 10px;
      &::before {
        content: "•"; 
        color: var(--color-primary);
        display: inline-block; 
        width: 1em;
        margin-left: -1em
      }
    }
  }
  iframe{
    width: 100%;
    min-height: 540px;
    margin-top: 50px;
  }
  .column_half {
    img {
      width: 100%;
      max-height: 251px;
      object-fit: cover;
    }
  }
  .column_third {
    margin-bottom: 0;
    &:nth-child(3n-1) {
      margin-bottom: 0;
    }
    .promo_img {
      margin: 0 auto 30px;
      align-items: center;
      justify-content: center;
      display: flex;
      max-width: 100%;
      img {
        max-width: 100%;
      }
    }
  }
  table {
    tr {
      td {
        padding-bottom: 15px;
        vertical-align: text-top;
        margin: 0;
        &:first-child {
          p {
            word-break: keep-all;
            margin-right: 25px;
            color: var(--color-primary);
            font-weight: 700;
          }
        }
      }
      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 0 15px;
    margin: 40px auto 0;
    .btn_simple-body{
      margin-top: 0;
    }
    iframe{
      margin-top: 20px;
      min-height: 188px;
    }
    p,
    li {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .title_wrapper{
      padding: 0 15px 20px;
      h2{
        margin: auto;
      }
    }
    .column_third {
      margin-bottom: 30px;
      .promo_img {
        margin: 0 auto 15px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }

    .column_half {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      + .column_half{
        margin-top: 30px;
      }
    }
    &-btn {
      .column-wrapper {
        margin: 0 auto 20px;
      }
    }

    table {
      tr {
        td {
          float: left;
          width: 100%;
          padding: 0;
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

.profile-button_container{
  display: flex;
  margin: 40px auto 0;
  width: 960px;
  max-width: 100%;
  align-items: center;
  
  .profile_img{
    width: 280px;
    height: 260px;
    background: var(--color-line-01);
    display: flex;
    img{
      display: block;
      margin: auto;
      max-width: 100%;
    }
    + .btn_simple-body {
      margin-left: 20px;
    }
  }
  .btn_simple-body {
    width: auto;
    max-width: calc(100% - 280px);
    margin: 20px auto 0;
    padding: 0;
    .btn_simple_wrapper {
      width: calc(33% - 12px);
      margin: 0 0 20px;
      &:nth-child(3n-1){
        margin-left: 20px;
        margin-right: 20px;
      }
    }
    + .profile_img{
      margin-left: 20px;
    }
    @media (max-width: 767px) {
      max-width: initial;
    }
  }
  @media (max-width: 640px) {
    flex-wrap: wrap;
    padding: 0 15px;
    .profile_img{
      margin: 20px auto 0;
      width: 200px;
      height: 200px;
      + .btn_simple-body {
        margin-left: 0;
      }
    }
    .btn_simple-body {
      width: 100%;
      justify-content: start;
      max-width: 100%;
      .btn_simple_wrapper{
        width: calc(50% - 10px);
        margin: 0 20px 20px 0;
        &:nth-child(3n-1) {
          margin: 0 20px 20px 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      + .profile_img{
        margin-top: 0;
        margin-left: auto;
      }
    }
  }
}

.latest_article{
  display: flex;
  margin: 40px auto 0;
  width: 960px;
  max-width: 100%;
  align-items: center;
  flex-wrap: wrap;
  max-height: 500px;
  overflow: auto;
  @media (max-width: 640px) {
    padding: 0 15px;
    margin-top: 25px;
    + .profile-button_container{
      margin-top: 20px;
    }
  }
  h5{
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 13px;
  }
  ul{
    width: 100%;
    border-top: 1px solid var(--color-line-03);
    li{
      border-bottom: 1px solid var(--color-line-01);
      font-size: 18px;
      display: flex;
      width: 100%;
      padding: 17px 0;
      span{
        margin-right: 15px;
        height: max-content;
        &.article-tag{
          color: var(--color-primary);
          border-radius: 4px;
          border: 1px solid var(--color-primary);
          padding: 1px 7px;
          font-weight: 700;
          white-space: nowrap;
          &.read{
            color: var(--color-line-01);
            border: 1px solid var(--color-line-01);
          }
        }
      }
      @media (max-width: 640px) {
        flex-wrap: wrap;
        font-size: 14px;
        
      span{
        &.article-tag{
          font-size: 12px;
        }
      }
        p{
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}

.article_banner{
  display: flex;
  margin: 30px auto 0;
  width: 960px;
  max-width: 100%;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 15px;
  .banner{
    overflow: hidden;
    width: 100%;
    height: 530px;
    margin-bottom: 40px;
    img{
      min-width: 100%;
    }
  }
  h5{
    font-size: 42px;
  }
  .headline {
    margin-top: 20px;
    border-top: 3px solid;
    width: 100%;
    padding: 24px 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    .tags{
      margin-bottom: 15px;
      span{
        color: var(--color-primary);
        font-weight: 700;
        margin-right: 32px;
      }
    }
    .date {
      margin-left: auto;
      font-weight: 700;
    }
  }
  +.article_contents{
    margin-top: 0;
  }

  @media (max-width: 640px) {
    margin: 20px auto 0;
    .banner{
      height: 188px;
      margin-bottom: 20px;
    }
    h5{
       font-size: 20px;
    }
    
    .headline {
      margin-top: 13px;
      border-top: 2px solid;
      font-size: 14px;
      padding:13px 0 0;
    } 
    
    +.article_contents{
      margin-top:20px;
    }
  }
}