@import "../../components/scss/mixins";
html, body{
  color: var(--color-primary-text);
  height: 100%; 
  font-weight: 500;
  @extend %fontYuGothic;
}
body{
  position: relative;
  display: initial;
}
ul, ol{
  padding: 0;
  margin: 0;
  list-style: none;
}
a{
  color: var(--color-primary-text);
  font-size: inherit;
  &:hover, 
  &:focus{
    color: var(--color-primary-text);
    text-decoration: none;
    outline: none;
  }
}
p{
  margin: 0;
}
.container {
  max-width: 100%;
}
button{
  &:focus, 
  &:active{
    outline: none;
  }
}
dl, dd{
  margin: 0
}
input, select, textarea{
  @extend %fontNotoSans;
}

select, input[type='radio'], input[type='checkbox']{
  cursor: pointer;
  &[disabled]{
    cursor: default;
  }
}
