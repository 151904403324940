@import 'mixins';

 .btn_rounded_wrapper {
  width: 300px;
  height: 50px;
  margin: 15px 0;
  + .btn_rounded_wrapper{
    margin-left: 20px;
  }
  &.middle {
    width: 300px;
  }

  &.large {
    font-size: 25px;
    line-height: 30px;
    height: 70px;
    width: 524px;
    .btn_rounded {
      border-radius: 75px;
    }
  }

  .btn_rounded {
    border-radius: 25px;
    color: var(--color-white);
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    width: 100%;
    border: 1px solid;
    height: 100%;
    border: 1px solid var(--color-primary);
    &.orange{
      background-color: var(--color-primary);
    }
    &.white {
      color: var(--color-dark-blue4);
      background-color: var(--color-white);
    }
  }

  @media screen and (max-width: 640px) {
    width: 240px;
    max-width: 100%;
    font-size: 13px;
    margin-bottom: 5px;
    height: 40px;
    position: relative;
    + .btn_rounded_wrapper{
      margin-left: 0;
    }

    &.middle {
      width: 240px;
    }

    &.large {
      width: 335px;
      line-height: 32px;
      font-size: 20px;
      height: 46px;
    }
  }
}
.btn_simple-body {
  width: 960px;
  max-width: 100%;
  margin: 30px auto 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  + .article_contents{
    margin-top: 20px;
  }
  
  + .btn_simple-body{
    margin-top: 0;
  }
  .btn_simple_wrapper {
    width: calc(50% - 15px);
    margin: 0 30px 20px 0;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &.full-width{
      width: 100%;
      margin-right: 0;
    }
    @media screen and (min-width: 641px) {
      &.pc-quarter{
        width: calc(25% - 17px);
        margin: 0 22px 20px 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    margin: 20px auto 0;
    padding: 0 15px;
    + .btn_simple-body{
      margin-top: 0;
    }
    + .article_contents{
      margin-top: 30px;
    }
    .btn_simple_wrapper {
      width: 100%;
      margin: 0 0 20px;
      &.mb-bigger{
        .btn_simple{
          min-height: 206px;
        }
      }
      &.mb-half{
        width: calc(50% - 15px);
        margin: 0 20px 20px 0;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.btn_simple_wrapper {

  .btn_simple {    
    color: var(--color-white);
    background-color: var(--color-primary);
    border-radius: 10px;
    font-size: 24px;
    min-height: 116px;
    width: 100%;
    height: 100%;
    display: block;
    box-shadow: 4px 4px 0 0 rgba(248, 147, 31, 0.3);
    white-space: unset;
    @extend %fontNotoSans;
    font-weight: 700;
    &.btn_approach {
      img {
        max-width: 100%;
        height: auto;
      }
    }
    &.orange {
      background-color: var(--color-orange);
      box-shadow: 4px 4px 0 0 rgba(243, 93, 36, 0.3);
    }
    &.dark_orange {
      background-color: var(--color-dark-orange);
      box-shadow: 4px 4px 0 0 rgba(243, 93, 36, 0.3);
    }
    &.blue {
      background-color: var(--color-light-blue);
      box-shadow: 4px 4px 0 0 rgba(102, 174, 222, 0.3);
    }
    &.yellow {
      background-color: var(--color-yellow);
      box-shadow: 4px 4px 0 0 rgba(255, 204, 000, 0.3);
      color: var(--color-primary-text);
    }
    &.white {
      background-color: var(--color-white);
      border: 1px solid var(--color-orange);
      color: var(--color-orange);
    }
  }
}



@media screen and (max-width: 640px) {
  .btn_simple_wrapper {
    margin: 0 10px  10px 0;
    &:nth-child(2n) {
      margin-right: 0;
    }
    .btn_simple {
      font-size: 19px;
      min-height: 96px;
    }
    &.middle {
      width: calc(100% - 5px);
    }
    &.large {
      width: calc(100% - 5px);
      height: 206px;
    }
  }

}