@import "mixins";
header {
  width: 100%;
}
.header {
  border-bottom: 5px solid var(--color-primary);
  background-color: var(--color-background-01);
  width: 100%;
  &.new_application {
    border: none;
    margin-bottom: 10px;
    .header_pos {
      > h2 {
        margin-bottom: 0;
      }
    }
  }
  &.cpad{
    .header_pos{
      margin-bottom: 10px;
      &-right {
        ul{
          li{
            &:last-child{
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
  &_pos {
    @extend %component;
    justify-content: left;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Noto Sans JP",sans-serif;
    font-weight: 500;
    flex-direction: row;
    position: relative;
    padding: 10px 15px 0;
    > h2:last-child {
      margin-bottom: 10px;
      @media screen and (max-width: 640px) {
        margin-bottom: 0;
      }
    }
    .burger-menu {
      text-align: center;
      position: absolute;
      right: 10px;
      display: none;
      top: 8px;
      i {
        color: var(--color-primary);
        font-size: 38px;
        line-height: 0;
      }
      span {
        font-size: 12px;
        display: block;
        font-weight: bold;
        margin-top: -5px;
      }
      .exit {
        display: none;
      }
      .close-text {
        display: none;
      }
      &.active {
        .close-text {
          display: block;
        }
        .open-text {
          display: none;
        }
        .exit {
          display: block;
        }
        .open {
          display: none;
        }
      }
    }
    &-left {
      margin-bottom: 0;
      img {
        max-width: 175px;
        max-height: 37px;
      }
      span{
        font-size: 14px;
      }
    }

    .btn_wrapper {
      .btn {
        margin-bottom: 0;
      }
    }
    &-right {
      margin-right: 0;
      margin-left: auto;
      ul {
        display: flex;
        align-items: center;
        li {
          padding: 0 0 0 10px;
          word-break: keep-all;
          &:last-child {
            padding-left: 20px;
          }
          span {
            display: inline-block;
            font-size: 18px;
          }
          small {
            font-size: 12px;
          }
          p {
            display: inline-block;
            font-size: 12px;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
    &-bottom {
      margin: 30px auto 0;
      font-weight: 600;
      width: 100%;
      @media screen and (min-width: 641px) {
        display: block !important;
      }
      &-menus {
        display: flex;
        align-items: center;
        li {
          flex: auto;
          text-align: center;
          font-size: 14px;
          padding-bottom: 12px;
          position: relative;

          &:first-child {
            a,
            span {
              border: none;
            }
          }

          a,
          > span {
            border-left: 1px solid var(--color-line-01);
            display: block;
            font-size: 14px;
            cursor: pointer;
            img {
              vertical-align: sub;
              margin-right: 8px;
              @media screen and (max-width: 640px) {
                vertical-align: middle;
              }
            }
          }
          &:hover {
            ul {
              &.sub-menus {
                @media screen and (min-width: 641px) {
                  display: grid;
                }
              }
            }
          }
          &:last-child:not(:first-child),
          &:nth-last-child(2):not(:first-child) {
            ul {
              &.sub-menus {
                right: 0;
                left: auto;
              }
            }
          }
          ul {
            &.sub-menus {
              display: none;
              position: absolute;
              word-break: keep-all;
              left: 0;
              border: 1px solid;
              border-top: 5px solid;
              border-color: var(--color-primary);
              padding: 22px 10px;
              margin-top: 12px;
              z-index: 9;
              background-color: var(--color-background-01);
              width: 715px;
              z-index: 99;
              grid-template-columns: repeat(
                auto-fit,
                minmax(min(100%, max(64px, 100%)), 1fr)
              );
              @media screen and (max-width: 960px) {
                width: auto;
              }
              a {
                border: none;
                position: relative;
                &:before {
                  right: 0;
                  position: absolute;
                }
              }
              > li {
                padding: 10px 20px;
                text-align: left;
                // min-width: calc(935px / 3);
                float: left;

                &:last-child {
                  padding-bottom: 0;
                  dl {
                    &:last-child {
                      border: none;
                    }
                  }
                }
                dl {
                  &:not(.sub-menus-item) {
                    > a {
                      padding-bottom: 15px;
                      &:before {
                        display: none;
                      }
                    }
                    &:last-of-type() {
                      padding-bottom: 20px;
                    }
                  }
                  + .sub-menus-item {
                    margin-top: 18px;
                  }
                }
                .sub-menus-item {
                  border-bottom: 1px solid var(--color-background-02);
                  flex-wrap: nowrap;
                  display: flex;
                  align-items: flex-start;

                  dd {
                    width: 100%;
                    ul {
                      display: flex;
                      flex-wrap: wrap;
                      li {
                        width: 50%;
                        text-align: left;

                        @media screen and (max-width: 960px) {
                          width: 100%;
                        }
                        a,
                        span {
                          display: inline-block;
                          padding: 0 15px;
                          font-weight: 500;
                          border: none;
                          &.has-link {
                            cursor: pointer;
                          }
                          &:before {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                  dt {
                    width: 200px;
                    padding-left: 15px;
                    font-size: 16px;
                    &.has-link {
                      cursor: pointer;
                    }
                    img {
                      width: 14px;
                    }
                  }
                  &.no-child {
                    dt {
                      width: auto;
                    }
                  }
                  &:last-child {
                    ul {
                      margin-bottom: 0;
                      li {
                        &:last-child {
                          padding-bottom: 0;
                        }
                      }
                    }
                  }
                  dt {
                    position: relative;
                    padding-left: 15px;
                    font-weight: 700;
                    margin-bottom: 15px;
                    &::after {
                      content: "";
                      width: 3px;
                      border-radius: 2px;
                      height: 20px;
                      top: 1px;
                      position: absolute;
                      left: 0;
                      background-color: var(--color-primary);
                    }
                  }
                  ul {
                    li {
                      padding-bottom: 15px;
                      a {
                        border: none;
                        text-align: left;
                        &.arrow-right {
                          &:before {
                            right: 0;
                            position: absolute;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 899px) {
    &_pos {
      &-bottom {
        &-menus {
          li {
            a,
            span {
              font-size: 14px;
              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    border: none;
    z-index: 99;
    //width: 100%;
    top: 0;
    left: 0;
    right: 0;
    width: inherit;
    max-width: inherit;
    box-sizing: border-box;
    &.new_application {
      position: relative;
      margin: 0;
      .header_pos {
        border: none;
      }
    }
    &_pos {
      border-bottom: 1px solid var(--color-line-01);
      padding: 20px 15px;
      &-right {
        display: none;
      }
      &-bottom {
        display: none;
        width: 298px;
        position: absolute;
        right: 0;
        top: 36px;
        background-color: var(--color-background-01);
        font-weight: 500;
        height: calc(100vh - 65px);
        overflow-y: auto;
        white-space: nowrap;
        padding-bottom: 20px;
        transition: transform 0.2s ease-in-out;
        &.open {
          white-space: normal;
        }
        .header_pos-right {
          display: block;
          ul {
            flex-wrap: wrap;
            border-bottom: 1px solid var(--color-line-01);
            padding: 15px;
            li {
              border: none;
              padding: 0;
              span {
                font-size: 14px;
                p {
                  font-size: 12px;
                  display: inline-block;
                  width: auto;
                }
              }
              p {
                font-size: 14px;
                width: 100%;
                display: block;
              }
              &:nth-child(2) {
                margin-top: 5px;
                span,
                p {
                  display: inline-block;
                  font-size: 12px;
                  width: auto;
                  white-space: nowrap;
                }
              }
            }
          }
        }
        &-menus {
          flex-wrap: wrap;
          li {
            width: 100%;
            text-align: left;
            font-size: 14px;
            padding-bottom: 0;
            &.has-sub-menus {
              &.open {
                > a,
                > span {
                  @media screen and (max-width: 640px) {
                    font-weight: 700;
                    color: var(--color-primary);
                  }
                  &:before {
                    display: none;
                  }
                }
                .sub-menus {
                  display: block;
                  > li {
                    width: 100%;
                    min-width: auto;
                    .sub-menus-item {
                      padding: 10px 0;
                      border-bottom: 1px solid var(--color-line-01);
                      flex-wrap: wrap;
                      dt {
                        width: 100%;
                        margin-bottom: 0;
                        &::after {
                          width: 3px;
                          border-radius: 2px;
                        }
                      }
                      dd {
                        ul {
                          li {
                            width: 100%;
                            a,
                            span {
                              display: block;
                              padding: 10px;
                            }
                          }
                        }
                      }
                      &:last-child {
                        border: none;
                      }
                      &.open {
                        &:before {
                          display: none;
                        }
                        ul {
                          display: block;
                        }
                      }
                      ul {
                        display: none;
                        font-weight: 500;
                        border-top: 1px solid var(--color-line-01);
                        margin: 10px 0 0;
                        li {
                          padding: 0 22px 0 0;
                          &:last-child {
                            a,
                            span {
                              padding-bottom: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              > a,
              > span,
              .sub-menus-item {
                position: relative;
                &:before {
                  content: " ";
                  position: absolute;
                  display: block;
                  background-color: var(--color-primary);
                  width: 1px;
                  margin-left: -5px;
                  right: 27px;
                  top: calc(50% - 7px);
                  bottom: 5px;
                  z-index: 9;
                  height: 15px;
                }
                &:after {
                  content: " ";
                  position: absolute;
                  display: block;
                  background-color: var(--color-primary);
                  height: 1px;
                  top: 50%;
                  right: 20px;
                  z-index: 9;
                  width: 15px;
                }
              }
              .sub-menus-item {
                &:before {
                  top: 13px;
                }
                &:after {
                  top: 20px;
                }
              }
            }
            a,
            span {
              border: none;
              padding: 10px 15px;
            }
            ul {
              &.sub-menus {
                background-color: var(--color-background-02);
                flex-wrap: wrap;
                column-count: 1;
                position: relative;
                border: none;
                padding: 0 0 0 40px;
                margin-top: 0;

                li {
                  padding: 0;
                  text-align: left;
                  border-bottom: 1px solid var(--color-line-01);
                  &:last-child {
                    border: none;
                  }
                  dl {
                    > a {
                      padding: 10px 0;
                      border-bottom: 1px solid var(--color-line-01);
                    }
                    + .sub-menus-item {
                      margin-top: 0;
                    }
                  }
                  a,
                  span {
                    padding: 0 40px 0 0;
                    white-space: break-spaces;
                    &.arrow-right {
                      &:before {
                        right: 20px;
                      }
                    }
                  }
                  .no-child {
                    &:before,
                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }
            .btn_wrapper {
              width: 100%;
              text-align: center;
              border-top: 1px solid var(--color-line-01);
              border-bottom: 1px solid var(--color-line-01);
              .btn_logout {
                background: transparent;
                width: 100%;
                border: none;
              }
            }
          }
        }
      }
      img {
        max-width: 111px;
      }
      .burger-menu {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .open-menu {
    position: relative;
    overflow: hidden;
    height: auto;
    &:before {
      content: "";
      background: rgba(0, 0, 0, 31%);
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 9;
    }
  }
}
