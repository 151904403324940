@import "mixins";
footer {
  margin: 70px auto 0;
}
.footer{
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Noto Sans JP",sans-serif;
  border-top: 1px solid var(--color-line-02);
  padding: 15px ;
  color: var(--color-secondary-text);
  font-size: 12px;
  // height: 50px;
  &_pos{
    max-width: 1200px;
    margin: auto;
    @extend %flexbox-left;
    &-left{
      img{
        max-width: 217px;
      }
    }
    &-right{
      margin-left: auto;
      margin-right: 0;
      padding-left: 20px;
      flex-wrap: wrap;
      ul{
        width: 100%;
        display: flex;
        margin-bottom: 10px;
        justify-content: flex-end;
        li{
          border-left: 1px solid var(--color-secondary-text);
          padding: 0 15px;
          &:first-child{
            border: none;
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    // height: 105px;
    &_pos{
      flex-wrap: wrap;
      &-left, &-right{
        width: 100%;
        text-align: center;
      }
      &-right{
        margin-top: 15px;
        padding: 0;
        p{
          font-size: 10px;
          line-height: 20px;
        }
        ul{
          justify-content: center;
          li{
            padding: 0 10px;
            &:first-child{
              padding-left: 0;
            }
          }
        }
      }
    }

  }
}