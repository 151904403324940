@import "../../components/scss/mixins";

.arrow-right {
  position: relative;
  img {
    margin-bottom: 6px;
  }
}

.arrow-down {
  position: relative;
  img {
    margin-bottom: 6px;
  }
}

.recaptcha {
  div:first-child{
    margin: 0 auto 20px auto;
  }
}

.arrow-up {
  position: relative;
  img {
    margin-bottom: 7px;
  }
}

.mb-20{
  margin-bottom: 20px;
}

.title-container{
  display:flex;
  width: 100%;
}

.input-container {
  input.label-box{
    border: none;
    padding: 0;
    pointer-events: none;
  }
  @extend %flexbox-left;
  span {
    &.required {
      @extend %required;
    }
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    left: 0;
    margin: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    & + label {
      position: relative;
      padding-left: 35px;
      margin-bottom: 0;
      line-height: 22px;
      &:before {
        @include tick-box(
          auto,
          0,
          16px,
          16px,
          3px,
          3px,
          var(--color-background-01)
        );
      }
      &:after {
        @include check-tick(auto, 10px, 0, 5px, 700, var(--color-white));
      }
    }
    &:checked {
      & + label {
        &:before {
          content: "";
          background: var(--color-primary);
        }
      }
    }
  }

  input[type="text"],
  select {
    @extend %textfield;

    + input, + .currency-sign-input-container {
      margin-left: 16px;
      @media screen and (max-width: 640px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
    &.xsmall {
      width: 72px;
    }
    &.small {
      width: 90px;
    }
    &.medium {
      width: 100px;
    }
    &.large {
      width: 150px;
    }
    &.xlarge {
      width: 500px;
      max-width: 100%;
    }
    &.full-width {
      width: 100%;
    }
  }

  input[type="text"] {
    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }
  textarea {
    @extend %textfield;
    min-height: 118px;
  }

  select {
    padding: 10px 35px 10px 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image:
      linear-gradient(45deg, transparent 50%, black 53%),
      linear-gradient(135deg, black 50%, transparent 53%);
    background-position:
      calc(100% - 22px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      7px 7px,
      7px 7px;
    background-repeat: no-repeat;
  }
}

.line-through {
  text-decoration: line-through;
}
.inline-block {
  display: inline-block !important;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-40 {
  margin-bottom: 32px !important;
}
.mt-40 {
  margin-top: 40px !important;
  @media screen and (max-width: 640px) {
    margin-top: 20px !important;
  }
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.ml-0 {
  margin-left: 0;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.pb-0 {
  margin-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pt-0 {
  padding-top: 0 !important;
}

.sp_only {
  display: none;
}

table {
  width: 100%;
}

.text-primary {
  color: var(--color-primary) !important;
}
.bold {
  font-weight: 700 !important;
}
.hide {
  display: none !important;
}
.hide-list-title {
  .list-title {
    display: none;
  }
}
.txt_left {
  text-align: left !important;
}
.txt_center {
  text-align: center;
}
.txt_right {
  text-align: right !important;
}
.text-center-left {
  text-align: center;
}
.no-background {
  background: none !important;
}
.no-border {
  border: none !important;
}
.no-border-bottom {
  border-bottom: none !important;
}
.hasError {
  border: 1px solid var(--color-attention-01) !important;
  background-color: var(--color-attention-02) !important;
  &.input-box {
    + .hasError {
      border-top: none !important;
    }
  }
}
.no-left-right-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}
.with-left-right-margin {
  margin-left: 16px !important;
  margin-right: 16px !important;
  width: calc(100% - 32px);
}
.img-max-width img {
  max-width: 100% !important;
  height: auto !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.mb-text-left {
  text-align: left !important;
}

.grid-column {
  display: grid;
}

.screen-loading {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-black);
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;

  .spinner {
    margin: auto;
  }
}

.mb-24-sp {
  @media screen and (max-width: 640px) {
    margin-bottom: 24px !important;
  }
}

.mb-full-width {
  @media screen and (max-width: 640px) {
    .pull-down {
      width: 100%;
    }
    input[type="text"],
    select {
      width: 100% !important;
    }
  }
}

.multiple-column-input {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .no-left-right-margin-sp {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  .text-center-left {
    text-align: left;
  }
  .pc_only {
    display: none !important;
  }
  .sp_only {
    display: block !important;
  }
  header {
    &.fixed {
      + main {
        margin-top: 65px;
      }
    }
  }
  main {
    display: flex;
    flex-wrap: wrap;
  }
  .input-container {
    input[type="text"],
    select {
      + input {
        margin-left: 0;
        margin-top: 24px;
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 991px) {
  main {
    > div {
      &:not(.hero-banner, .carousel, .title_wrapper) {
        width: calc(100% - 30px);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
