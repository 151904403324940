@import "mixins";
.pagination {
  font-weight: 700;
  nav {
    .sxa-paginationnumber {
      background-color: var(--color-background-02);
      border-radius: 4px;
      padding: 7px 12px;
      margin: 0 4px;
      display: inline-block;
      user-select: none;
      &.active {
        background-color: var(--color-primary);
        color: var(--color-white);
      }
    }
    .more {
      margin: 0 4px;
      user-select: none;
    }
    span,
    a {
      &:not(.sxa-paginationnumber, .more) {
        position: relative;
        font-size: 0;
        display: inline-block;
        line-height: 32px;
        &.inactive {
          color: var(--color-line-01);
        }
        &:first-child {
          width: 25px;
          img {
            position: relative;
            bottom: 4px;
          }
        }
        &:nth-child(2) {
          font-size: 14px;
        }
        &:nth-last-child(2) {
          font-size: 14px;
        }
        &:last-child {
          img {
            position: absolute;
            bottom: -8px;
          }
        }

        &.inactive {
          &:last-child {
            img {
              filter: invert(60%) sepia(0%) saturate(170%) hue-rotate(173deg) brightness(83%) contrast(87%);
            }
          }

          &.first-child {
            img {
              filter: invert(60%) sepia(0%) saturate(170%) hue-rotate(173deg) brightness(83%) contrast(87%);
            }
          }
        }
        &.disabled {
          pointer-events: none;
        }
      }
    }
  }
}

.pagination_wrapper {
  @extend %flexbox-center;
  width: 100%;
  margin: 20px auto 0;
  padding: 0 18px;
  &.PL-01{
    .search_wrapper {
      width: 100%;
    }
  }
  .page-result {
    margin-right: auto;
    .description-content {
      display: inline-block;
      margin: 0;
      max-width: none;
      &.D-02 {
        span {
          font-size: 20px;
        }
      }
    }
  }
  .pagination {
    margin-right: 20px;
    margin-left: auto;
  }

  @media screen and (max-width: 640px) {
    margin: 0 auto;
    padding: 0;
    .page-result {
      .description-content {
        &.D-02 {
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}
