/*
 * Img banner
 */

.img_banner_cpad {
  img {
    width: 100%;
  }
}

.img_banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 320px;
  overflow: hidden;
  background-color: var(--color-dark-yellow);

  &.pattern_1 {
    .banner_image {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 960px;
      height: 300px;
      overflow: hidden;
      border-radius: 5px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .banner_title {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-flex;
      align-items: center;
      margin-right: 20px;
      color: var(--color-dark-yellow);
      font-weight: 700;
      font-size: 18px;

      .banner_title_icon {
        height: 50px;
      }

      .banner_title_text {
        margin: auto auto 6px 8px;
        line-height: 1.75;
      }
    }
  }

  &.pattern_2 {
    .banner_image_left {
      position: relative;
      top: 20px;
      left: 60px;
      width: 420px;
    }

    .banner_title {
      position: relative;
      right: 20px;
      z-index: 1;
      display: flex;
      flex-direction: column;

      .banner_title_text {
        color: var(--blue-text-color);
        font-weight: 700;
        font-size: 48px;
        white-space: nowrap;
      }

      .banner_tag {
        display: inline-block;
        height: 25px;
        margin-top: 10px;
        padding: 0 28px;
        color: var(--white-text-color);
        font-weight: 500;
        font-size: 19px;
        line-height: 1.3;
        white-space: nowrap;
        background-color: var(--color-normal-blue);
        border-radius: 12px;
      }
    }

    .banner_image_right {
      position: relative;
      right: 60px;
      width: 250px;
    }
  }

  @media screen and (max-width: 640px) {
    width: auto;
    height: 125px;

    &.pattern_1 {
      .banner_image {
        width: 365px;
        height: 115px;
        background-size: 365px 115px;
      }

      .banner_title {
        margin-right: 12px;
        font-size: 11px;

        .banner_title_icon {
          height: 28px;
        }

        .banner_title_text {
          margin: auto auto 6px 4px;
        }
      }
    }

    &.pattern_2 {
      .banner_image_left {
        top: 5px;
        left: 50px;
        width: auto;
        height: 115px;
      }

      .banner_title {
        left: 16px;

        .banner_title_text {
          font-size: 24px;
        }

        .banner_tag {
          height: 14px;
          margin-top: 0;
          padding: 0 14px;
          font-size: 10px;
          border-radius: 5px;
        }
      }

      .banner_image_right {
        right: 45px;
        width: auto;
        height: 130px;
      }
    }
  }
}
