@import "mixins";
.heading_wrapper{
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Noto Sans JP",sans-serif;
  margin-bottom: 24px;
  &.underline{
    border-bottom: 1px solid var(--color-accent-02);
    margin-bottom: 32px;
    h1, h2, h3{
      margin-bottom: 15px;
    }
  }
  &.with-top-bottom-margin {
    margin: 15px 0;
  }
  &.inline{
    @extend %flexbox-left;
    gap: 0 15px;
    em{
      font-style: normal;
    }
    &.underline {
      em {
        margin-bottom: 15px;
      }
    }
  }
  &.no-border{
    h3{
      padding-left: 0;
      font-size: 16px;
      margin: 0;
      &:before{
        display: none
      }
    }
  }
  &.with-left-right-margin{
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);
  }
  &.number{
    @extend %flexbox-left;
    border-bottom: 3px solid var(--color-primary);
    padding: 15px 0;
    width: 100%;
    margin-bottom: 32px;
    display: inline-flex;
    align-items: flex-start;
    span{
      background-color: var(--color-background-02);
      width: 32px;
      height: 32px;
      font-size: 14px;
      color: var(--color-primary);
      border-radius: 50%;
      text-align: center;
      font-weight: 700;
      line-height: 30px;
      margin-right: 15px;
    }
    h1, h2, h3{
      font-size: 22px;
      margin: 0;
      width: calc(100% - 50px);
      white-space: pre-line;
  
    }
    &.entered{
      h1, h2, h3{
        position: relative;
        padding-right: 45px;
        max-width: calc(100% - 50px);
        width: auto;
        line-height: normal;
        &:before{
          @include tick-box(5px, auto, 16px, 16px, 6px, 50%, var(--color-primary));
        }
        &:after{
          @include check-tick(10px, 10px, 8px, auto, 700, var(--color-white));
        }
      }
      .btn_plan{
        display: block;
        font-size: 14px;
        color:var(--color-primary);
        margin-right: 15px;
        margin-left: auto;
        font-weight: 700;
        &:hover, &:active, &:focus{
          outline: none;
        }
      }
    }
  }
  &:not(.number, .entered){
    span{
      display: none;
    }
    h1, h2, h3{
      font-size: 18px;
      position: relative;
      padding-left: 20px;
      &::before{
        content: "";
        width: 6px;
        border-radius: 3px;
        background-color: var(--color-accent-02);
        height: 100%;
        position: absolute;
        left: 0;
      }
    }
  }
  .btn_plan {
    display: none;
  }
  h4{
    font-size: 16px;
  }
  p{
    font-size: 16px;
  }
  span{
    &.required {
      border-radius: 12px;
      background-color: var(--color-primary);
      font-size: 12px;
      padding: 2px 10px;
      color: var(--color-white);
      margin-left: 12px;
    }
  }
  @media screen and (max-width: 640px) {
    &.with-left-right-margin{
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
    &.underline{
      h1, h2,h3{
        margin-bottom: 15px;
      }
    }
    &.number{
      padding: 12px 0;
      &.entered{
        h3{
          padding-right: 35px;
          &:before{
            top: 2px;
          }
          &:after{
            top: 4px;
          }
        }
      }
      h1, h2, h3{
        font-size: 18px;
      }
    }
    h4{
      font-size: 14px;
    }
    p{
      font-size: 14px;
    }
    &:not(.number, .entered){
       h3{
        font-size: 14px;
      }
    }
  }
}