%component {
  width: 960px;
  max-width: 100%;
  padding: 0 15px;
  margin: auto;
  @extend %flexbox-center;
}

%flexbox-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

%flexbox-left {
  display: flex;
  //align-items: center;
  align-items: baseline;

  justify-content: left;
  flex-wrap: wrap;
}

%required {
  border-radius: 12px;
  background-color: var(--color-primary);
  font-size: 12px;
  padding: 2px 10px;
  color: var(--color-white);
}
%textfield {
  width: 320px;
  max-width: 100%;
  border: 1px solid var(--color-line-01);
  font-size: 14px;
  color: var(--color-secondary-text);
  border-radius: 4px;
  padding: 10px 15px;
  &:active,
  &:focus {
    outline: none;
    border: 1px solid var(--color-primary);
  }
  &:disabled {
    background-color: var(--color-background-02);
    &:active,
    &:focus {
      outline: none;
      border: 1px solid var(--color-line-01);
    }
  }
}

%fontYuGothic {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Noto Sans JP",sans-serif;
}
%fontNotoSans {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Noto Sans JP",sans-serif;
}

@mixin tick-box($right, $left, $width, $height, $top, $radius, $bg) {
  content: "";
  position: absolute;
  right: $right;
  left: $left;
  background-color: $bg;
  border: 1px solid var(--color-primary);
  width: $width;
  height: $height;
  top: $top;
  border-radius: $radius;
}

@mixin check-tick($right, $size, $top, $left, $weight, $color) {
  content: "L";
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Noto Sans JP",sans-serif;
  transform: scaleX(-1) rotate(-45deg);
  display: inline-block;
  font-size: $size;
  position: absolute;
  top: $top;
  left: $left;
  color: $color;
  font-weight: $weight;
  right: $right;
}

@mixin content-icon-name($name) {
  content: url("../../../img/icons/#{$name}.svg");
}
