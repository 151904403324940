@import "mixins";
$font_size-pc-default: 16px;
$font_size-sp-default: 14px;
.description-content {
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
  max-width: calc(100% - 32px);
  &.float-left {
    float: left;
  }
  &.underline {
    border-bottom: 1px solid var(--color-line-01);
  }
  &.no-bottom-margin {
    margin-bottom: 0;
  }
  &.no-right-margin {
    margin-right: 0;
  }
  &.no-left-margin {
    margin-left: 0;
  }
  &.no-left-right-margin {
    margin-right: 0;
    margin-left: 0;
  }
  & > a {
    text-decoration: underline;
  }
  &.large {
    span {
      font-size: 20px;
    }
  }
  a {
    text-decoration: underline;
    color: var(--color-primary);
  }
  p {
    font-size: 16px;
  }
  span {
    font-size: 16px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    p {
      font-size: 14px;
    }
    span {
      font-size: 14px;
    }
    &.large {
      span {
        font-size: 18px;
      }
    }
  }
}

.register_wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-bottom: 24px;
  .register-column {
    @extend %flexbox-center;
    background-color: var(--color-background-02);
    border-radius: 4px;
    align-items: flex-start;
    padding: 24px 0;
    margin-top: 24px;
    flex-flow: row wrap;
    width: 100%;
    .column {
      padding: 0 20px;
      width: 50%;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      border-right: 1px solid var(--color-line-01);
      .btn_container {
        margin-bottom: 0;
        .btn {
          margin: 0;
        }
      }
      .sp_only {
        .title {
          margin-bottom: 15px;
        }
      }
      &:last-child {
        border: none;
      }
      .title {
        margin-bottom: 20px;
      }
      ul {
        padding-left: 20px;
        list-style: decimal;
      }
      .register-container {
        margin-top: 20px;
        &.qr {
          img {
            width: 110px;
          }
        }
        &.id {
          img {
            width: 248px;
          }
        }
        img {
          margin: auto;
          display: block;
          max-width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    flex-flow: column;
    .register-column {
      flex-wrap: wrap;
      .column {
        width: 100%;
        border-bottom: 1px solid var(--color-line-01);
        border-right: none;
        margin-bottom: 25px;
        padding-bottom: 25px;
        .title {
          text-align: center;
        }
        &:last-child {
          border: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

.decoration-content {
  margin-left: 16px;
  margin-right: 16px;
  width: calc(100% - 32px);

  &.C-07 {
    .content-title {
      span {
        @extend %fontYuGothic;
      }
    }
  }
  .input-form {
    > label {
      font-weight: 700;
      font-size: 16px;
      @media screen and (max-width: 640px) {
        font-size: 14px;
      }
    }
  }
  .content-title {
    h5,
    span {
      font-size: $font_size-pc-default;
      margin: 0;
      @extend %fontNotoSans;

      &:last-of-type {
        font-weight: bold;
      }
    }

    &.small {
      font-size: $font_size-pc-default - 2px;
      h5,
      span {
        font-size: $font_size-pc-default - 2px;
      }
    }
    &.big {
      font-size: $font_size-pc-default + 2px;

      h5,
      span {
        font-size: $font_size-pc-default + 2px;
      }
    }
  }
  .multiple_buttons {
    padding: 0 20px 20px;
    ul {
      padding-top: 20px;
      @extend %flexbox-center;
      border-top: 1px solid var(--color-line-01);
      li {
        width: 50%;
        padding: 5px 20px;
        .btn_wrapper {
          width: 100%;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
  .information {
    font-size: 16px;

    a {
      color: var(--color-accent-03);
    }
  }
  &.service {
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    padding: 20px;
    h5 {
      margin: 5px 0;
      font-size: 16px;
    }
    .information {
      @extend %flexbox-left;
      dl {
        font-size: 16px;
        display: flex;
        margin-top: 5px;
        width: 100%;
        dt {
          font-weight: 500;
        }
      }
    }
  }
  &.shaded {
    padding: 5px 20px 20px;
    box-shadow: 0 0 10px -5px var(--color-black);
    border-radius: 8px;
    .content-title {
      h5 {
        padding: 15px 0;
      }
    }
    ul {
      li {
        border-top: 1px solid var(--color-line-01);
        padding: 15px 0;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  &.benefits {
    border-radius: 4px;
    width: 565px;
    max-width: 100%;
    border: 1px solid var(--color-line-01);
    padding: 15px;
    box-shadow: 10px 10px 0 0 var(--color-background-02);
    position: relative;
    margin: 50px auto 48px;
    .content-title {
      @extend %flexbox-center;
      margin-top: -30px;
      h5 {
        position: relative;
        padding: 0 20px;
        z-index: 1;
        background-color: var(--color-background-01);
        text-align: center;
        &:after {
          content: "";
          width: calc(100% - 10px);
          height: 65%;
          background-color: var(--color-accent-01);
          top: 10px;
          position: absolute;
          left: 5px;
          z-index: -1;
        }
      }
    }
    .benefits-image {
      background-color: var(--color-background-02);
      margin: 15px 0;
      min-height: 105px;
      width: 100%;
      border-radius: 4px;
      @extend %flexbox-center;
      img {
        max-width: 100%;
      }
    }
    > p {
      padding: 5px 10px;
    }
    .input-form {
      margin: 10px 10px 0;
    }
  }
  &.terms,
  &.procedure {
    border-radius: 4px;
    max-width: 100%;
    .content-title {
      color: var(--color-primary);
      text-align: center;
      padding: 20px 15px;
    }
  }
  &.terms {
    border: 2px solid var(--color-primary);
    .content-title {
      border-bottom: 2px solid var(--color-primary);
      h5 {
        font-size: 18px;
      }
    }
    .information {
      padding: 24px 40px;
      span {
        &.input-container {
          position: relative;
          display: inline-block;
          margin-left: 10px;
          input[type="checkbox"] {
            + label {
              padding-left: 28px;
              position: initial;
              &::before {
                border-radius: 50%;
              }
            }
          }
        }
      }
      ul {
        margin: 15px 0 20px;
        li {
          border-bottom: 1px solid var(--color-line-01);
          padding: 10px 0;
          .btn_wrapper {
            justify-content: left;
          }
          &.active {
            position: relative;
            padding-right: 45px;
            &:before {
              @include tick-box(
                5px,
                auto,
                16px,
                16px,
                15px,
                50%,
                var(--color-primary)
              );
            }
            &:after {
              @include check-tick(
                10px,
                10px,
                15px,
                auto,
                700,
                var(--color-white)
              );
            }
          }
        }
      }
      .btn_wrapper {
        @extend %flexbox-center;
      }
    }
  }

  &.procedure {
    border: 1px solid var(--color-primary);
    .content-title {
      border-bottom: 1px solid var(--color-primary);
    }
    .information {
      ul {
        li {
          border-bottom: 1px solid var(--color-line-01);
          padding: 22px 75px;
          width: 100%;
          align-items: center;
          @extend %flexbox-left;
          .btn_wrapper {
            margin-left: auto;
            .btn_default {
              max-width: 220px;
            }
          }
          .count {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: var(--color-primary);
            @extend %fontNotoSans;
            @extend %flexbox-center;
            color: var(--color-white);
            margin-right: 20px;
          }

          .icon {
            color: var(--color-primary);
            background-color: var(--color-background-02);
            border-radius: 50%;
            margin-right: 15px;
            font-size: 22px;
            width: 48px;
            height: 48px;
            @extend %flexbox-center;
          }
          span {
            &:not(.icon, .full-width) {
              max-width: calc(100% - 300px);
            }
            &.full-width {
              max-width: calc(100% - 60px);
            }
          }
          h6 {
            font-size: 16px;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  &.application {
    margin-bottom: 50px;
    .application_wrapper {
      border-radius: 4px;
      border: 1px solid var(--color-line-01);
      text-align: center;
      padding: 20px;
      width: 600px;
      max-width: 100%;
      margin: auto;
      .btn_container {
        margin: 20px auto 0;
      }
      margin-bottom: 40px;
    }
    @media screen and (max-width: 640px) {
      margin-bottom: 32px;
      .application_wrapper {
        border: none;
        padding: 0;
        .description-content {
          margin-bottom: 24px;
        }
      }
    }
    .information {
      ul {
        li:not(.channel-list) {
          border-bottom: 1px solid var(--color-line-01);
          padding: 22px 0;
          width: 100%;
          @extend %flexbox-left;
          .btn_wrapper {
            margin-left: auto;
          }
          .count {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: var(--color-primary);
            @extend %fontNotoSans;
            @extend %flexbox-center;
            color: var(--color-white);
            margin-right: 20px;
          }

          .icon {
            color: var(--color-primary);
            background-color: var(--color-background-02);
            border-radius: 50%;
            margin-right: 15px;
            font-size: 22px;
            width: 48px;
            height: 48px;
            @extend %flexbox-center;
          }
          span {
            &:not(.icon) {
              max-width: calc(100% - 300px);
            }
          }
          h5 {
            font-size: 16px;
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  &.individual {
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    max-width: 100%;
    &.no-border {
      border: none;
      .information {
        padding: 0;
      }
    }
    &.no-icon {
      img {
        display: none;
      }
    }
    .content-title {
      border-bottom: 1px solid var(--color-primary);
      padding: 15px 20px;
      label {
        margin: 0;
      }
      h5 {
        display: inline-block;
      }
      img {
        margin-right: 8px;
        vertical-align: sub;
      }
    }

    .information {
      padding: 20px;
      @extend %flexbox-left;
      .btn_wrapper {
        .btn_default {
          &.arrow-right {
            max-width: 270px;
          }
        }
      }
      .multiple-button {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .B-01 {
          margin: 24px 0 0 auto;
        }
        .B-02 {
          margin: 24px auto 0 10px;
        }
      }
      ul {
        width: 100%;
        li {
          @extend %flexbox-left;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            width: 112px;
            display: inline-block;
            margin-right: 5px;
            max-width: 50%;
            margin-right: 5px;
          }
          p {
            display: inline-block;
            font-weight: 700;
            @extend %fontNotoSans;
            overflow-wrap: break-word;
            flex: 1;
          }
        }
      }
      .btn_wrapper {
        margin: 24px auto 0;
      }
      .bottom-section {
        padding: 0;
        margin-top: 24px;
        width: 100%;
        @extend %flexbox-left;
        &:has(> .btn_wrapper) {
          border-top: 1px solid var(--color-line-01);
        }
        .btn_wrapper {
          width: 50%;
          padding: 0 15px;
          margin: 24px 0 0 0;
          .btn {
            width: 100%;
            margin-bottom: 0;
          }
          &:nth-child(odd) {
            margin-left: auto;
            margin-right: 0;
          }
        }
        .description-content {
          width: 100%;
          margin: 15px 0 0 auto;
          padding: 0 15px;
          text-align: center;
          p {
            width: 50%;
            font-size: 12px;
            margin-left: auto;
            @media screen and (max-width: 640px) {
              width: 100%;
            }
          }
        }
      }
    }
    .list_wrapper {
      &.note {
        background: transparent;
        border-radius: 0;
        border-top: 1px solid var(--color-line-01);
        width: calc(100% - 40px);
        padding: 30px 0 0;
        margin-top: 10px;
        .input-form {
          justify-content: left;
          margin-top: 20px;
          > label {
            font-weight: 700;
          }
          .input-box {
            margin-top: 5px;
          }
        }
      }
    }
  }
  &.amount {
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    max-width: 100%;
    .information {
      ul {
        li {
          padding: 0 20px;
          .detail {
            @extend %flexbox-left;
            //align-items: baseline;

            padding: 15px 0;
            > div {
              &:nth-child(2n + 1) {
                flex: 1 0 70%;
              }
              &:nth-child(2n) {
                flex: 1 0 30%;
              }
            }
            .text-big {
              font-size: 24px;
              color: var(--color-primary);
              @extend %fontNotoSans;
            }
          }
          &:last-child {
            .detail {
              border-bottom: none;
            }
          }
        }
      }
    }
    &.display1 {
      .information {
        ul {
          li {
            .detail {
              border-bottom: 1px solid var(--color-line-01);
              .txt-left {
                p {
                  margin-top: 5px;
                  font-size: 16px;
                }
              }
            }
            ul {
              padding: 10px 0;
              border-bottom: 1px solid var(--color-primary);
              li {
                padding: 5px 0;
                dl {
                  font-size: 14px;
                  display: flex;
                  dt {
                    font-weight: 500;
                    max-width: calc(100% - 100px);
                  }
                  dd {
                    margin-left: auto;
                    margin-right: 0;
                  }
                }
              }
            }

            &:last-child {
              ul {
                border: none;
              }
            }
          }
        }
      }
    }
    &.display2 {
      .content-title {
        h5 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
          margin-top: 10px;
          @media screen and (max-width: 640px) {
            font-size: 12px;
          }
        }
      }
      .information {
        ul {
          li {
            padding: 0;
            .detail {
              padding: 15px 20px;
              border-bottom: 1px solid var(--color-primary);
            }
            .sub1 {
              padding: 20px;
              > li {
                border-bottom: 1px solid var(--color-line-01);
                margin-bottom: 30px;
                &:last-child {
                  margin-bottom: 0;
                }
                > .content-title {
                  border-bottom: 1px solid var(--color-line-01);
                  padding-bottom: 15px;
                }
              }
              h6 {
                font-size: 16px;
              }
              .detail {
                border: none;
              }
              .sub2 {
                span {
                  font-weight: normal;
                  font-size: 14px;
                }
                li {
                  padding: 0 0 0 20px;
                  .detail {
                    border-bottom: 1px solid var(--color-line-01);
                    padding: 15px 0;
                  }
                  &:last-child {
                    .detail {
                      border: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.instruction {
    border-radius: 4px;
    border: 2px solid var(--color-line-01);
    padding: 20px 5px 0 20px;
    .information {
      max-height: 405px;
      overflow: scroll;
      padding-right: 20px;
      padding-bottom: 0;
      ul {
        li {
          margin-bottom: 20px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    .content-title {
      h5,
      span {
        font-size: $font_size-sp-default;
        text-align: left;

        // &:last-of-type {
        //   &:before {
        //     content: "\A";
        //     display: block;
        //   }
        // }
      }

      &.small {
        font-size: $font_size-sp-default - 2px;
        h5,
        span {
          font-size: $font_size-sp-default - 2px;
        }
      }
      &.big {
        font-size: $font_size-sp-default + 2px;
        h5,
        span {
          font-size: $font_size-sp-default + 2px;
        }
      }
    }
    .multiple_buttons {
      ul {
        padding-top: 20px;
        li {
          &:first-child {
            padding-top: 0;
          }
          width: 100%;
          padding: 5px 0;
        }
      }
    }
    &.service {
      padding: 20px 15px;
      h5 {
        font-size: 14px;
      }
      .information {
        dl {
          font-size: 14px;
        }
      }
    }
    &.shaded {
      padding: 5px 15px 15px;
    }
    &.benefits {
      margin: 35px 0 45px;
      .content-title {
        h5 {
          line-height: normal;
        }
      }
    }
    &.terms {
      .information {
        padding: 20px 15px;
        p {
          line-height: 21px;
        }
      }
      .content-title {
        h5 {
          font-size: 16px;
        }
      }
    }
    &.decoration-content {
      &.procedure {
        h5 {
          text-align: center;
        }
        ul {
          li {
            justify-content: center;
            padding: 22px 15px;
            flex-direction: column;
            .btn_wrapper {
              .btn_default {
                max-width: 100%;
                width: 320px;
              }
            }
            .count {
              margin: 0 auto 15px;
            }
            span {
              text-align: center;
              margin-bottom: 0;
              h6 {
                margin-bottom: 10px;
              }
              &:not(.icon) {
                max-width: calc(100% - 65px);
              }
            }
            .btn_wrapper {
              margin: 10px auto 0;
            }
          }
        }
      }
      &.individual {
        .content-title {
          padding: 15px;
          img {
            display: none;
          }
        }
        .information {
          padding: 20px 15px;
          .btn_wrapper {
            width: 100%;
            .btn_default {
              &.arrow-right {
                width: 100%;
                max-width: none;
              }
            }
          }
          ul {
            li {
              display: inline-block;
              span {
                min-width: 112px;
                width: auto;
              }
              p {
                //font-weight: 600;
              }
            }
          }
          .bottom-section {
            margin-top: 0;
            &:has(> .btn_wrapper) {
              padding: 10px 0 0 0;
              margin-top: 30px;
            }
            .btn_wrapper {
              width: 100%;
              padding: 0;
              margin: 16px 0 0 0;
              .btn {
                margin: 0;
              }
            }
            .description-content {
              margin-top: 15px;
              width: 100%;
              padding: 0;
            }
          }
        }

        .list_wrapper {
          &.note {
            width: calc(100% - 30px);
            padding: 25px 0 0;
            margin-top: 5px;
          }
        }
      }
      &.amount {
        .information {
          ul {
            li {
              padding: 0 15px;
              .detail {
                .text-big {
                  font-size: 20px;
                }
                .txt-left {
                  margin-top: 10px;
                  //order: 3;
                  font-size: 12px;
                }

                > div {
                  flex: auto;
                  width: 100%;
                  &:nth-of-type(1),
                  &:nth-of-type(2) {
                    width: 50%;
                  }
                  &:nth-of-type(3) {
                    order: 4;
                  }
                  &:nth-of-type(4) {
                    order: 3;
                  }
                }
              }
            }
          }
        }
        &.display1 {
          .information {
            ul {
              li {
                .detail {
                  .txt-left {
                    p {
                      font-size: 12px;
                      margin-top: 0;
                    }
                  }
                }
              }
            }
          }
        }
        &.display2 {
          .information {
            > ul {
              &:not(.sub1) {
                > li {
                  > .detail {
                    > div {
                      &:nth-of-type(1) {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
            ul {
              li {
                padding: 0;
                .detail {
                  padding: 15px;
                }
                .sub1 {
                  padding: 24px 15px 15px;
                  .sub2 {
                    li {
                      padding: 0 0 0 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.instruction {
        padding: 15px 5px 0 15px;
        .information {
          max-height: 340px;
        }
      }

      &.application {
        .application_wrapper {
          margin-bottom: 20px;
        }
        .information {
          ul {
            li {
              span {
                margin-bottom: 15px;
                &:not(.icon) {
                  max-width: calc(100% - 65px);
                }
              }
            }
          }
        }
      }
      .information {
        font-size: 14px;
      }
    }
  }
}
