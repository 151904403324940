@import "mixins";
.accordion {
  margin: 0 auto;
  width: 565px;
  max-width: 100%;

  &.S-18 {
    width: 100%;
    .accordion-title {
      margin-bottom: 20px;
      background-color: transparent;
      &::after {
        @include content-icon-name("arrow_down_small");
        display: inline-block;
        margin-left: 10px;
      }
      &.activeAccordion {
        border-bottom: none;
        &::after {
          transform: rotate(180deg);
        }
      }
    }
    .hideAccordion {
      display: none;
    }
    .activeAccordion {
      display: block;
    }
    .form_wrapper-input {
      margin-bottom: 10px !important;
      transition: height 0.5s ease;
    }
  }
  &.price-breakdown {
    .accordion-detail {
      .quantity {
        text-align: center !important;
      }
    }
  }
  .accordion-title {
    border-radius: 4px;
    background-color: var(--color-background-02);
    text-align: center;
    padding: 11px;
    cursor: pointer;
    position: relative;
    h5 {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      display: inline-block;
    }
    img {
      display: inline-block;
      margin-left: 10px;
    }
    &.active {
      border-bottom: none;
      img {
        transform: rotate(180deg);
        top: 10px;
      }
    }
  }
  &.show {
    .accordion-title {
      border-bottom: none;
      img {
        transform: rotate(180deg);
        top: 5px;
      }
    }
  }
}
.precaution-box {
  background-color: #ffe1aa;
  color: #000;
  border: 1px solid #e87100;
  padding: 16px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  word-break: break-all;

  ul {
    list-style: none; 
    padding-left: 0; 
  }
  li {
    position: relative;
    padding-left: 20px; 
    margin-bottom: 8px;
  }
  li::before {
    content: '■';
    position: absolute;
    left: 0;
    top: 0.2em;
    font-size: 14px; 
    color: #e87100;
  }
  &.confirmation {
    margin-bottom: 32px;
  }
}
.google_f {
  font-family: 'Ubuntu', sans-serif;
}