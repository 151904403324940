@import "mixins";
$circle-width: 32px;
$circle-border-width: 6px;
$circle-outer-box-width: calc($circle-width + $circle-border-width*2);
.title_wrapper{
  @extend %flexbox-center;
  @extend %fontNotoSans;
  background-color: var(--color-primary);
  color: var(--color-white);
  font-weight: 700;
  min-height: 152px;
  padding: 42px 15px;
  width: 100%;
  margin-bottom: 32px;
  h1 { 
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
  ul{
    @extend %component;
    font-size: 12px;
    margin: 0 auto 70px;
    width: 705px;
    @media screen and (max-width: 991px) {
      width: 648px;
    }
    li{
      flex: auto;
      position: relative;
      width: 135px;
      @media screen and (max-width: 991px) {
        width: 120px;
      }
      p{
        white-space: nowrap;
        font-size: 12px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      .circle{
        border-radius: 50%;
        width: $circle-width;
        height: $circle-width;
        border: 3px solid var(--color-accent-03);
        position: absolute;
        left: calc(50% - 16px);
        top: 30px;
        background-color: var(--color-primary);
        z-index: 1;
        &:after{
          position: absolute;
          content: '';
          border-radius: 50%;
          border: $circle-border-width solid var(--color-primary);
          width: $circle-outer-box-width;
          height: $circle-outer-box-width;
          left: -9px;
          top: -9px;
        }
      }
      &:after{
        content: "";
        position: absolute;
        /*
        height: 4px;
        width: 100%;
        background-color: var(--color-accent-03);
        left: 50%;*/
        top: 44px;
        border: 2px solid var(--color-accent-03);
        border-radius: 2px;
        width: calc((100% - $circle-outer-box-width) * .9 - 2px);
        left: calc(50% + $circle-outer-box-width/2 + 6px);
        z-index: 2;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
      //&.current, 
      &.active{
        &:after{
          background-color: var(--color-background-01);
          border-color: var(--color-background-01);
        }
        .circle{
          border: 3px solid var(--color-background-01);
        }
      }
      &.current {
        .circle{
          border: 3px solid var(--color-background-01);
        }
      } 
      &.active{
        .circle{
          background:var(--color-background-01);
          &:before{
            @include check-tick(auto, 20px, -3px, 8px, 100, var(--color-primary));
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    min-height: 80px;
    padding: 15px 15px 0;
    text-align: left;
    &.PT-02{
      padding: 24px 16px 32px;
    }
    &.PT-01{
      > h1{
        margin-bottom: 16px;
      }
    }
    
    h1{
      font-size: 20px;
      width: auto;
      margin-left: 0;
      margin-right: auto;
      text-align: left;
    }
    > p{
      margin-left: auto;
      margin-right: 0;
      font-size: 12px;
      color: var(--color-nextpage-title);
    }
    ul{
      margin: 0 auto 46px;  
      li{
        width: auto;
        p{
          display: none;
        }
        .circle{
          width: calc($circle-width / 2);
          height: calc($circle-width / 2);
          border: 2px solid var(--color-accent-03);
          left: 0;
          top: 10px;
          &:after{
            border: 1px solid var(--color-primary);
            width: calc($circle-outer-box-width / 2);
            height: calc($circle-outer-box-width / 2);
            left: -6px;
            top: -6px;
          }
        }
        &:after{
          height: 2px;
          top: 17px;
          left: 0;
          border: 1px solid var(--color-accent-03);
          width: calc((100% - $circle-outer-box-width/2) * .9 - 2px);
          //left: calc(50% + $circle-outer-box-width/2 + 6px);
          left: calc($circle-outer-box-width / 2);
        }
        &:nth-last-of-type(2){
          &:after {
            //left: calc(50% + $circle-outer-box-width/2);
            left: calc($circle-outer-box-width / 2);
          }
        }
        
        &.current, 
        &.active{
          .circle{
            border: 2px solid var(--color-background-01);
          }
        }
        &.active{
          .circle{
            &:before{
              font-size: 10px;
              top: -2px;
              left: 3px;
            }
          }
        }
        &:last-child {
          width: 16px;
          flex: none;
        }
      }
    }
  }
}

.h_title{
  padding: 30px 0;
  h2{
    @extend %fontNotoSans;
    font-size: 20px;
    position: relative;
    padding-left: 30px;
    margin: 0;
    &:before{
      content: "";
      position: absolute;
      width: 10px;
      height: 32px;
      background-color: var(--color-primary);
      left: 0;
      top: -5px;
    }
  }
  
  @media screen and (max-width: 640px) {
    h2{
      border-bottom: 3px solid var(--color-primary);
      min-width: 216px;
      display: table;
      padding: 0 30px;
      margin: auto;
      text-align: center;
      padding-bottom: 10px;
      
      &::before{
        display: none;
      }
    }
  }
}
