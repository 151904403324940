/*
 * Color Variables
 */
:root {
  --color-primary: #f76b1f;
  --color-primary-text: #231815;
  --color-white: #fff;
  --color-black: #000;
  --color-secondary-text: #3f3e3e;
  --color-background-01: #ffffff;
  --color-background-02: #efefef;
  --color-background-03: #707070;
  --color-background-04: #f0f0f0;
  --color-background-05: #fafafa;

  --color-line-01: #d0d0d0;
  --color-line-02: #cccccc;
  --color-line-04: #fef9f6;
  --color-line-05: #c6c6c6;
  --color-line-06: #ed6c00;
  --color-line-07: #aad1ef;
  --color-accent-01: #ffff01;
  --color-accent-02: #a0d2f2;
  --color-accent-03: #c45800;
  --color-accent-04: #d29768;
  --color-accent-05: #fff9f6;
  --color-attention-01: #dc3545;
  --color-attention-02: #faeced;
  --color-nextpage-title: var(--color-background-02);

  // additional color from staff portal
  --color-orange: #f35d24;
  --color-dark-orange: #c45800;
  --color-light-blue: #66aede;
  --color-yellow: #ffff01;
  --color-line-03: #3f3e3e;
  --color-normal-red: #dc3545;

  // TODO will replace this color once confirmed with the client.
  --color-blue: #0b3182;
  --color-gray: #969696;
}
