
@import "mixins";
.estimate_wrapper{
  .sp-estimate-close{
    display: none;
  }
  &-display {
    box-shadow: 0px 0px 6px -1px var(--color-line-02);
    width: 280px;
    background: var(--color-background-01);
    section{
      @extend %flexbox-left;
      padding: 20px 15px 15px;   
      margin-bottom: 20px;
      background: var(--color-background-02);
      ul{
        &.section-list{
          border-top: 2px solid var(--color-line-01);
          margin-top: 10px;
          padding-top: 15px;
        }
        li{
          dl{
            display: flex;
            font-size: 12px;
            dt{
              font-weight: 500;
              max-width: calc(100% - 100px);
            }
            dd{
              margin-left: auto;
              margin-right: 0;
            }
          }
        }
      }
      &:last-child{
        &.white-bg{
          padding-bottom: 20px;
        }
      }
      &.white-bg {
        background: var(--color-background-01);
        padding: 0 15px 24px;   
        margin-bottom: 0;
        span{
          margin-right: 0;
        }
      }
      label{
        font-size: 14px;
        @extend %fontNotoSans;
        margin-bottom: 0;
        margin-top: 10px;
        font-weight: 700;
        width: 100%;
      }
      h2{
        font-size: 20px;
        width: 100%;
        margin-bottom: 10px;
      }
      h3{
        position: relative;
        font-size: 18px;
        padding-left: 10px;
        width: 100%;
        &:before{
          content: '';
          position: absolute;
          width: 4px;
          border-radius: 2px;
          height: 18px;
          background-color: var(--color-primary);
          left: 0;
          bottom: 2px;
        }
      }
      
      h4{
        margin-top: 20px;
        width: 100%;
        font-size: 14px;
        @extend %fontNotoSans;
      }
     
      p {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        @extend %fontNotoSans;
        margin-bottom: 5px;
        flex-wrap: wrap;
        word-break: break-word;
        text-align: right;
        &.text-small{
          font-size: 12px;
          span{
            font-size: 12px;
            margin: 0;
          }
        }
        span{
          font-size: 15px;
          margin-right: 5px;
          &.regular{
            font-weight: normal;
            margin: 0;
          }
          &.text-left {
            font-size: 12px;
            margin-left: 0;
            margin-right: auto;
          }
          &.text-big{
            font-size: 24px;
          }
        }
      }
      ul{
        width: 100%;
        margin-top: 16px;
        li{
          font-size: 14px;
          margin-bottom: 8px;
          p{
            display: inline-block;
            text-align: left;
            width: auto;
            font-weight: 700;
          }
          span{
            min-width: 95px;
            display: inline-block;
            margin-right: 10px;
          }
        }
        &.s-list{
          font-weight: 700;
          margin-top: 20px;
          li{
            padding-bottom: 10px;
            border-bottom: 2px solid var(--color-line-01);
          }
        }
      }
      .tabset {
        .tab-panels{
          margin-top: 20px;
          table {
            padding: 0;
            border-spacing: 0;
            thead{
              tr{
                th{
                  &::before{
                    display: none;
                  }
                }
              }
            }
            tbody{
              tr{
                border-left: 0;
                border-bottom: 1px solid var(--color-background-02);
                border-right: 0;
                td{
                  padding: 8px;
                }
              }
            }
          }
        }
      }
      table{
        margin: 10px 0;
        border-bottom: 2px solid var(--color-line-01);
        &.no-margin{
          margin: 0;
          tbody{
            tr{
              &:first-child{
                td{
                  padding-top: 0;
                }
              }
            }
          }
        }
        thead{
          background-color: var(--color-background-02);
          th{
            font-weight: 500;
            @extend %fontNotoSans;
            padding: 5px;
            &:first-child{
              border-radius: 8px 0 0 8px;
            }
            &:last-child{
              border-radius: 0 8px 8px 0;
              text-align: right;
            }
          }
        }
        tbody{
          tr{
            position: relative;
            border-bottom: 1px solid var(--color-background-02);
            &.heading {
              font-size: 12px;
              font-weight: 700;
              border: none;
            }
            &:first-child{
              td{
                padding-top: 15px;
              }
            }
            &:last-child{
              border: none;
            }
            td{
              padding: 8px;
              vertical-align: top;
              &:first-child{
                padding-right: 10px;
              }
              &:last-child:not(:first-child){
                text-align: right;
                width: 90px;
                word-break: break-word;
              }
            }
          }
        }
        tfoot{
          tr{
            border-top: 1px solid var(--color-line-01);
          }
          td{
            padding: 8px;
            &:first-child{
              padding-right: 10px;
            }
            &:last-child:not(:first-child){
              text-align: right;
              width: 100px;
              word-break: break-word;
            }
          }
        }
      }
    }
    
  }
  .sp-display{
    box-shadow: 0px 0px 6px -1px var(--color-line-02);
    width: 360px;
    padding: 10px 0;
    max-width: 100%;
    display: none;
    padding-top: 10px;
    @extend %flexbox-left;
    h2{
      font-size: 12px;
      @extend %fontNotoSans;
    }
    &_amount {
      border-right: 1px solid var(--color-line-01);
      padding: 0 7px;
      @extend %flexbox-left;
      align-items: center;
      width: 45%;
      .amount-detail {
        width: 100%;
        p{
          text-align: right;
        }
      }
      &:last-child{
        border: none;
        width: 55%;
        .amount-detail {
          width: 90px;
          padding-right: 5px;
        }
      }
      .btn_wrapper {
        margin-left: auto;
      }
    }
  }
  @media screen and (min-width: 641px) {
    .sp-estimate-modal{
      display: block !important;
    }
  }
  @media screen and (max-width: 640px) {
    section{
      h2{
        font-size: 16px;
        margin-bottom: 5px;
      }
      table{
        thead{
          th{
            font-size: 12px;
            &:last-child {
              border-radius:0 4px 4px 0;
            }
            &:first-child {
              border-radius: 4px 0 0 4px;
            }
          }
        }
      }
    }
    .sp-display{
      display: flex;
      &_amount{
        .btn_wrapper{
          &.primary{
            .btn {
              margin-top: 0;
            }
          }
        }
      }
    }
    .sp-estimate-modal{
      display: none;
      .sp_estimate-display{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        width: 100%;
        max-height: calc(100% - 38px);
        overflow: hidden;
        outline: 0;
        display: flex;
        padding: 15px;
        height: 100%;
        .estimate_wrapper-display{
          box-shadow: none;
          margin: auto;
          width: 328px;
          max-width: 100%;
          overflow: auto;
          max-height: 100%;
        }
      }
      
      .sp-estimate-close {
        right: calc(50% - 47px);
        bottom: 0;
        color: var(--color-white);
        background: transparent;
        box-shadow: none;
        border: none;
        bottom: 0;
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 9999;
        p {
          font-size: 14px;
          margin: 0 10px;
          order: 2;
        }
        span{
          font-size: 30px;
        }
      }
      .sp-display-backdrop{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        background-color: var(--color-black);
        opacity: .5;
      }
    }
  }
}


