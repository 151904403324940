@import "mixins";
.link_wrapper {
  margin: 0 16px 32px;
  max-width: calc(100% - 32px);
  &.icon-right{
    a{
      img {
        display: inline-block;
        margin-right: 9px;
        margin-bottom: 2px;
      }
    }
  }
  a {
    color: var(--color-accent-03);
    font-weight: 500;
    font-size: 16px;
    padding-left: 0;
    img {
      display: inline-block;
      margin-right: 9px;
      margin-bottom: 2px;
    }
  }
  &.icon{
    a{
      position: relative;
      padding-left: 25px;
      img{
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
  }
  &.no-icon{
    a{
      padding-left: 0;
      img{
        display: none;
      }
    }
  }
  span {
    border-bottom: 1px solid;
  }
  @media screen and (max-width: 640px) {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    
    span{
      font-size: 14px;
    }
  }
}
