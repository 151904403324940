/*
 * Staff Header Line Component
*/
.header_line_cpad {
  width: 960px;
  max-width: 100%;
  margin: auto;
  padding: 0;
  min-height: 60px;
  font-weight: 500;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  &-logo{
    img{
      max-width: 175px;
      max-height: 37px;
    }
  }
  &-icons {
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;
    ul {
      flex-direction: row;
      align-items: center;   
      display: flex;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }
  }
  li {
    margin: 0 10px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    p {
      font-size: 18px;
      span{
        font-size: 12px;
        margin: 0 15px;
      }
    }
  }
  .icon-notif__badge {
    position: absolute;
    top: -7px;
    right: -10px;
    min-width: 20px;
    height: 20px;
    background: var(--color-normal-red);
    color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    padding: 5px;
  }

  .icn-notif {
    img {
      width: 30px;
    }
  }

  .icn-cpad {
    img {
      width: 32px;
    }
  }

  .icn-logout {
    img {
      width: 30px;
    }
  }

  .btn_cpad {
    font-size: 18px;
    border-radius: 30px;
    padding: 2px 25px;
    background-color: var(--color-dark-orange);
    color: var(--color-white);
    line-height: normal;
    &:hover {
      color: var(--color-white);
    }
  }

  @media screen and (max-width: 640px) {
    padding: 15px;
    border-bottom: 1px solid var(--color-line-01);
    &-logo{
      img{
        max-width: 111px;
      }
    }
    .navbar-brand img {
      height: auto;
      width: 128px;
    }

    li {
      margin: 0 8px 0 8px;
      img {
        height: auto;
        width: 22px;
      }
      p {
        display: none;
      }
    }

    .icn-notif {
      img {
        height: auto;
        width: 20px;
      }
    }

    .icon-notif__badge {
      font-size: 10px;
      min-width: 17px;
      height: 17px;
    }

    .btn_cpad {
      font-size: 10px;
      padding: 5px 14px;
    }
  }
}
