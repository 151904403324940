@import "mixins";
.alert-message{
  border-radius: 5px;
  background-color: var(--color-attention-02);
  width: 960px;
  max-width: 100%;
  padding: 25px;
  margin: 30px auto 24px;
  &.with-icon{
    border-left: 8px solid var(--color-attention-01);
    @extend %flexbox-left;
    .alert-icon{
      margin-right: 30px;
      align-self: center;
      span{
        background-color: var(--color-attention-01);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @extend %flexbox-center;
      }
    }
    .alert-text{
      width: calc(100% - 70px);  
      h2{
        font-size: 16px;
        color: var(--color-attention-01);
        @extend %fontNotoSans;
      }
      ul{
        padding: 10px 5px 0;
        li{
          position: relative;
          padding-left: 12px;
          margin-bottom: 10px;
          &:last-child{
            margin-bottom: 0;
          }
          &:before{
            left: 0;
            position: absolute;
            top: -4px;
          }
        }
      }
    }
  }
  &.warning{
    padding: 20px 25px;
    .note-title{
      @extend %flexbox-left;
      i{
        font-size: 18px;
        margin-right: 8px;
      }
      p{
        max-width: calc(100% - 30px);
      }
    }
  }
  p{
    font-size: 14px;
    margin-bottom: 5px;
    &:last-child{
      margin: 0;
    }
  }
  
  @media screen and (max-width: 640px) {
    padding: 15px;
    margin: 16px auto 24px;
    width: calc(100% - 30px);
    
    &.warning{
      padding: 15px;
      width: 100%;
    }
    p{
      font-size: 14px;
    }
    &.with-icon{
      .alert-icon{
        margin-right: 10px;
      }
      
      .alert-text{
        width: calc(100% - 50px);  
      }
    }
  }
}