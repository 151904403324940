@import "mixins";
.list_wrapper {
  border-radius: 4px;
  width: 960px;
  max-width: 100%;
  margin: auto;
  &.underline {
    display: inline-block;
    h5 {
      border-bottom: 2px solid var(--color-primary);
    }
    &.two-column {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      margin: 0 auto 32px;
      ul {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        li {
          width: calc(50% - 10px);
          margin-right: 10px;
          &:nth-child(2n) {
            margin-left: 10px;
            margin-right: 0;
          }
          span {
            width: 120px;
          }
          p {
            width: calc(100% - 125px);
            margin-top: 0 !important;
          }
        }
      }
    }
  }
  &.accordion {
    border-bottom: none;
    > ul {
      margin-top: 0;
    }
    .information {
      @extend %flexbox-left;
      ul {
        width: 100%;
        li {
          margin-bottom: 10px;
          padding: 0;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            min-width: 90px;
            width: auto;
            display: inline-block;
            font-weight: 500;
            padding: 0;
          }
          p {
            display: inline-block;
            font-weight: 700;
            @extend %fontNotoSans;
            overflow-wrap: break-word;
          }
        }
      }
    }
    ul {
      padding: 0;
      li {
        border: none;
        padding: 0 0 10px;
      }
    }
    .accordion-title {
      border-bottom: 1px solid var(--color-line-01);
      background-color: transparent;
      border-radius: 0;
      padding: 5px 0;
      text-align: left;
      display: flex;
      h5 {
        font-weight: 700;
        width: calc(100% - 135px);
        line-height: 22px;
      }
      span {
        float: right;
        padding-right: 30px;
        display: none;
        font-weight: 500;
        width: 130px;
        text-align: right;
        max-width: 100%;
        &.a-open {
          display: block;
        }
      }
      &.hide-accordion {
        img {
          content: "";
        }
      }
      img {
        position: absolute;
        right: 5px;
        top: 13px;
      }
      &.active {
        span {
          &.a-open {
            display: none;
          }
          &.a-close {
            display: block;
          }
        }
      }
    }
    .accordion-info {
      height: 0;
      transition: height 0.5s ease;
      &.show {
        .accordion-detail {
          visibility: visible;
          table {
            width: 100%;
            table-layout: fixed;
          }
          th:first-child {
            width: 60%;
          }
          th:last-child {
            width: 30%;
          }
          td:first-child,
          td:last-child {
            white-space: normal;
            text-overflow: clip;
          }
        }
        @media screen and (max-width: 640px) {
          height: auto !important;
        }
      }
      .accordion-detail {
        visibility: hidden;
      }
    }
    table {
      font-size: 16px;
      width: calc(100% + 10px);
      margin-left: -5px;
      border-bottom: 1px solid var(--color-line-01);
      thead {
        th {
          font-weight: 500;
          padding: 4px 12px;
          font-size: 14px;
          &:first-child {
            border-radius: 4px 0 0 4px;
          }
          &:last-child {
            text-align: right;
            border-radius: 0 4px 4px 0;
          }
          background-color: var(--color-background-02);
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid var(--color-line-01);
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          td {
            padding: 10px 5px;
            vertical-align: top;
            &:last-child {
              text-align: right;
              width: 150px;
              span {
                font-weight: 500;
                font-size: 12px;
                display: block;
                width: 100%;
                padding: 0;
              }
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
      tfoot {
        tr {
          border-top: 2px solid var(--color-line-01);
          td {
            padding: 10px 5px;
            vertical-align: top;
            &:last-child {
              text-align: right;
              width: 100px;
              span {
                font-weight: 500;
                font-size: 12px;
                display: block;
                width: 100%;
                padding: 0;
              }
            }
          }
        }
      }
    }
    + p {
      margin-top: 10px !important;
    }
  }

  &.list-description {
    border-bottom: none;
    border: 1px solid var(--color-line-01);
    padding: 25px;
    p {
      margin-bottom: 6px;
    }
    .list-description-title {
      background-color: transparent;
      border-radius: 0;
      padding: 5px 0;
      text-align: left;
      display: flex;
      h5 {
        font-weight: 700;
        width: 100%;
        line-height: 22px;
      }
    }
    table {
      font-size: 16px;
      width: 100%;
      tbody {
        tr {
          border-bottom: 1px solid var(--color-line-01);
          td {
            padding: 10px 0;
            vertical-align: top;
            &:last-child {
              text-align: right;
              width: 100px;
              span {
                font-weight: 500;
                font-size: 12px;
                display: block;
                width: 100%;
                padding: 0;
              }
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    + p {
      margin-top: 10px !important;
    }
  }
  &.two-column {
    background-color: var(--color-background-02);
    border-radius: 5px;
    box-shadow: 0 0 10px -5px var(--color-black);
    margin-bottom: 64px;
    .column-list {
      padding: 0 30px 30px;
      display: flex;
      justify-content: left;
      flex-wrap: nowrap;
      &_buttons {
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        .btn_wrapper {
          margin-bottom: 10px;
          &:last-child {
            margin: 0;
          }
        }
      }
      ul {
        padding: 0;
        width: 100%;
        li {
          border: none;
          padding: 0;
          font-size: 18px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          span {
            width: auto;
            padding-right: 0;
            font-weight: inherit;

            &.pc_only {
              @extend %fontNotoSans;
            }
            &.text-big {
              font-size: 30px;
            }
            &.text-small {
              font-size: 16px;
              span {
                font-size: 18px;
                vertical-align: baseline;
              }
            }
          }
          &:last-child {
            padding-top: 20px;
            margin: 10px 0 0;
            border-top: 1px solid var(--color-line-01);
          }
        }
      }
    }
  }

  &.single-column {
    background-color: var(--color-background-01);
    border-radius: 5px;
    box-shadow: 0 0 10px -5px var(--color-black);
    margin-bottom: 64px;
    .column-list {
      padding: 0 30px 30px;
      @extend %flexbox-center;
      ul {
        padding: 0;
        width: 100%;
        margin-bottom: 25px;
        li {
          border: none;
          padding: 15px 0;
          font-size: 16px;
          border-bottom: 1px solid var(--color-line-01);
          display: flex;
          span {
            width: auto;
            font-weight: inherit;
          }
          p {
            width: 100%;
          }
        }
      }
    }
    .tag {
      min-width: 90px;
      span {
        text-align: center;
      }
      &.gray span {
        background-color: var(--color-gray);
        color: white;
      }
      &.blue span {
        background-color: var(--color-blue);
        color: white;
      }
    }
  }

  &.multiple-column {
    background-color: var(--color-background-01);
    border-radius: 5px;
    box-shadow: 0 0 10px -5px var(--color-black);
    margin-bottom: 64px;
    .column-list {
      padding: 0 24px 0;
      @extend %flexbox-left;
      position: relative;
      .hidden-line {
        position: absolute;
        bottom: 30px;
        width: 100%;
        display: flex;
        height: 2px;
        background-color: var(--color-background-01);
        left: 0;
      }
      ul {
        width: 100%;
        padding: 0;
        @extend %flexbox-left;
        li {
          border: none;
          padding: 0;
          width: 25%;
          padding-bottom: 30px;
          border-bottom: 1px solid var(--color-line-01);
          margin-bottom: 30px;
          a {
            @extend %flexbox-left;
            text-align: center;
            border-right: 1px solid var(--color-line-01);
            img {
              margin: 0 auto 8px;
              width: 83px;
            }
            p {
              width: 100%;
              margin: 0;
              font-weight: 700;
              span {
                width: auto;
                padding: 0;
                margin-left: 5px;
              }
            }
          }
          &:nth-child(4n) {
            a {
              border: none;
            }
          }
          &:nth-child(n + 5) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.tabset {
    box-shadow: 0 0 10px -5px var(--color-black);
    padding-bottom: 20px;
    &.P-06 {
      box-shadow: none;
      padding: 0;
      .tab-menu {
        display: flex;
        width: 100%;
        border: none;
        font-weight: 500;
        input {
          &:checked + label {
            font-weight: 700;
            color: var(--color-primary-text);
          }
        }
        label {
          font-size: 16px;
          width: 50%;
          text-align: center;
          border-bottom: 1px solid var(--color-line-01);
          @extend %fontYuGothic;
        }
      }
      .tab-panels {
        .tab-panel {
          padding: 32px 0 0;
          &.active > div {
            display: block;
          }
          .decoration-content {
            &.individual {
              &.C-10 {
                margin-bottom: 24px;
                ul {
                  padding: 0;
                  li {
                    padding: 0;
                    border: none;
                    span {
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
          .L-02 {
            table {
              thead, tbody {
                .quantity {
                  padding-right: 0;
                  text-align: right;
                  width: 10%;
                }
              }
              thead {
                tr {
                  th {
                    padding: 4px 12px;
                  }
                }
              }
              tbody {
                tr {
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  td {
                    padding: 10px 5px;
                    font-size: 16px;
                  }
                }
              }

              @media screen and (max-width: 640px) {
                thead {
                  display: table-header-group;
                }
                tbody {
                  tr {
                    td {
                      display: table-cell;
                      font-size: 14px;
                      padding: 10px 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .tab-menu {
      font-size: 14px;
      @extend %fontNotoSans;
      font-weight: 700;
      position: relative;
      border-bottom: 1px solid var(--color-line-01);
      input {
        visibility: hidden;
        position: absolute;
        &:checked {
          & + label {
            border-bottom: 2px solid var(--color-primary);
            color: var(--color-primary);
          }
        }
      }
      label {
        margin: 0;
        padding: 12px 30px;
        cursor: pointer;
      }
    }
    .tab-panels {
      .tab-panel {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    table {
      thead {
        tr {
          th {
            padding: 10px 15px;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              height: 1px;
              width: 100%;
              background: var(--color-line-01);
              left: 0;
            }
            &.title {
              width: 90px;
            }
            &.date {
              width: 150px;
            }
            &.price {
              width: 100px;
            }
          }
        }
      }

      tbody {
        font-size: 14px;
        .right {
          text-align: right;
        }
        tr {
          border-bottom: 1px solid var(--color-line-01);
          border-left: 30px solid transparent;
          border-right: 30px solid transparent;
          &.no-data {
            text-align: center;
            border: none;
            td {
              padding: 20px 15px 0;
            }
          }
          td {
            padding: 12px 15px;
            &.text-xsmall {
              font-size: 10px;
            }
            &.text-small {
              font-size: 12px;
            }
            &.text-normal {
              font-size: 14px;
              @extend %fontNotoSans;
              .bold {
                font-size: 20px;
              }
            }
          }
        }
      }
      tr {
        td,
        th {
          &:last-child {
            max-width: 200px;
          }
        }
      }
    }
  }

  &.note {
    padding: 15px 15px 20px;
    &.primary {
      border: 1px solid var(--color-primary);
      h4 {
        font-size: 16px;
        padding: 10px 10px 0;
      }
      ul {
        margin-bottom: 20px;
        li {
          padding: 0 0 0 10px;
          &:before {
            top: 10px;
            @media screen and (max-width: 640px) {
              top: 8px;
            }
          }
          &:last-child {
            border-bottom: 1px solid var(--color-line-01);
            padding-bottom: 27px;
          }
        }
        &:last-child {
          li {
            border: none;
          }
        }
        &:last-child {
          margin-bottom: 0;
          li {
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    &.secondary {
      padding: 20px 25px;
      background-color: var(--color-background-02);
      ul {
        margin-top: 3px;
        padding: 0 23px;
        li {
          margin-top: 5px;
          padding: 0 0 0 7px;
          border: none;
          &::before {
            background-color: var(--color-primary-text);
            top: 10px;
          }
        }
      }
      p {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .note-title {
        padding-left: 25px;
        font-size: 16px;
        position: relative;
        i {
          color: var(--color-primary);
          margin-right: 3px;
          position: absolute;
          left: 0;
        }
      }
      &:not(.medium) {
        .note-title {
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
      &.center {
        text-align: center;
        .note-title {
          padding: 0;
          margin-bottom: 20px;
        }
      }
    }
    &.medium {
      width: 580px;
      max-width: 100%;
      padding: 16px 16px 16px 16px;
    }

    ul {
      li {
        position: relative;
        padding: 10px 0 10px 10px;
        border: none;
        &:before {
          content: "";
          position: absolute;
          top: 20px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: var(--color-primary);
          left: 0;
        }
        a {
          color: var(--color-primary);
          text-decoration: underline;
        }
      }
    }
  }
  &.with-icon {
    @extend %flexbox-left;
    .icon-list {
      @extend %flexbox-center;
      width: 100%;
      .icon {
        color: var(--color-primary);
        background-color: var(--color-background-02);
        border-radius: 50%;
        font-size: 22px;
        width: 48px;
        height: 48px;
        @extend %flexbox-center;
      }
      span {
        p {
          &:first-child {
            margin-bottom: 5px;
          }
        }
        &:last-child {
          padding-left: 15px;
          width: calc(100% - 50px);
        }
      }
    }
  }
  &.with-background {
    background-color: var(--color-background-02);
    margin-top: 20px;
    margin-bottom: 20px;

    &.L-11 {
      padding: 24px;
      p {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
    &.L-10 {
      padding: 24px;
      dl {
        @extend %flexbox-left;
        align-items: center;
        font-size: 16px;
        dt {
          font-size: 20px;
          margin-right: 20px;
        }
      }
    }
    ul {
      padding: 0;
      li {
        padding: 15px 20px;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        &:last-child {
          border-bottom: none;
        }
        span {
          margin: 0;
        }
        p {
          width: calc(100% - 180px);
        }
      }
    }
  }
  h5 {
    font-size: 16px;
    padding-bottom: 10px;
    @extend %fontNotoSans;
  }
  &.text-only {
    ul {
      padding: 0;
      li {
        border: none;
        padding: 5px 0;
      }
    }
  }
  ul {
    padding: 0 8px;
    li {
      padding: 10px 5px;
      font-size: 16px;
      border-bottom: 1px solid var(--color-line-01);
      span {
        font-weight: 700;
        width: 220px;
        display: inline-block;
        vertical-align: top;
        padding-right: 10px;
      }
      p {
        width: calc(100% - 225px);
        vertical-align: top;
        display: inline-block;
      }
    }
  }
  @media screen and (max-width: 640px) {
    &.underline {
      ul {
        padding: 0;
      }
      &.two-column {
        width: 100%;
        ul {
          li {
            width: 100%;
            margin-right: 0;
            &:nth-child(2n) {
              margin-left: 0;
            }
            span {
              width: 80px;
              margin: 0;
            }
            p {
              width: calc(100% - 85px);
            }
          }
        }
      }
    }
    ul {
      li {
        font-size: 14px;
        line-height: 21px;
        span {
          padding-right: 0;
          width: 100%;
          margin-bottom: 8px;
        }
        p {
          width: 100%;
        }
      }
    }

    &.accordion {
      .accordion-title {
        h5 {
          font-size: 14px;
        }
      }
      table {
        font-size: 14px;
        td {
          vertical-align: top;
          &:last-child {
            padding-left: 30px;
            white-space: pre;
          }
        }
      }
    }

    &.list-description {
      padding: 15px;
      p {
        margin-bottom: 15px;
      }
      .list-description-title {
        h5 {
          font-size: 14px;
        }
      }
      table {
        font-size: 14px;
        border-bottom: 1px solid var(--color-line-01);
        td {
          vertical-align: top;
          &:last-child {
            padding-left: 30px;
            white-space: pre;
          }
        }
      }
    }

    &.tabset {
      &.P-06 {
        .tab-menu {
          label {
            font-size: 16px;
          }
        }
      }
      .tab-menu {
        label {
          font-size: 12px;
          padding: 12px 10px;
        }
      }
      table {
        thead {
          display: none;
        }
        tbody {
          tr {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            &.no-data {
              padding-top: 20px;
              display: block;
              td {
                max-width: 100%;
              }
            }
            td {
              display: block;
              font-size: 12px;
              padding: 5px 5px 0;
              &:first-child {
                padding-top: 10px;
              }
              &:last-child {
                padding-bottom: 10px;
              }
            }
          }
        }
      }
    }

    &.note {
      padding: 15px 5px;
      ul {
        li {
          padding: 10px 0 0 10px;
          font-size: 12px;
          &:last-child {
            padding-bottom: 10px;
          }
        }
      }
      &.secondary {
        padding: 16px 14px;
        border-radius: 4px;

        ul {
          padding: 0 20px;
        }
        p {
          padding: 0 15px;
        }
        .note-title {
          font-size: 12px;
          i {
            left: 0;
          }
        }
        &:not(.medium) {
          .note-title {
            font-size: 14px;
          }
        }

        &.center {
          padding: 20px;
          .note-title {
            text-align: left;
          }
        }
      }
    }

    &.with-background {
      &.L-11 {
        padding: 15px;
        p {
          font-size: 14px;
        }
      }
      &.L-10 {
        padding: 15px;
        dl {
          flex-wrap: wrap;
          font-size: 14px;
          dt {
            width: 100%;
            margin-right: 0;
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
      }
      ul {
        li {
          padding: 12px 15px 15px;
          span {
            width: 96px;
          }
          p {
            width: calc(100% - 96px);
          }
        }
      }
    }
    &.two-column {
      margin: 0 auto 48px;
      width: calc(100% - 30px);
      .h_title {
        padding: 30px 0 20px;
      }
      .column-list {
        flex-wrap: wrap;

        .btn_wrapper {
          width: 100%;
        }
        ul {
          li {
            text-align: center;
            justify-content: center;
            &:first-child {
              font-size: 14px;
            }
          }
        }
      }
    }
    &.multiple-column {
      margin: 0 auto 48px;
      background-color: transparent;
      box-shadow: none;
      width: calc(100% - 30px);
      .h_title {
        padding-top: 0;
      }
      .column-list {
        flex-wrap: nowrap;
        padding: 0;
        .hidden-line {
          display: none;
        }
        ul {
          border: none;
          padding: 0;
          align-items: unset;
          li {
            border: 1px solid var(--color-line-01);
            width: 33%;
            padding: 15px 10px;
            margin: 0;
            border-right: none;
            border-top: none;
            display: flex;
            justify-content: center;
            &:last-child {
              border-bottom: 1px solid var(--color-line-01);
              border-right: 1px solid var(--color-line-01);
              border-top: none;
              &:not(:nth-child(3n + 3)) {
                width: 33.3%;
              }
            }
            &:nth-child(-n + 3) {
              border-top: 1px solid var(--color-line-01);
            }
            &:nth-child(3n + 3) {
              border-right: 1px solid var(--color-line-01);
            }
            a {
              border: none;
              img {
                width: 50px;
              }
              p {
                span {
                  width: 100%;
                  padding: 0;
                  margin-left: 0;
                  margin: 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
    &.single-column {
      width: calc(100% - 30px);
      margin: 0 auto 48px;
      background-color: transparent;
      box-shadow: none;
      .h_title {
        padding-top: 0;
      }
      .column-list {
        padding: 0;
        ul {
          li {
            font-size: 14px;
            &:first-child {
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}
