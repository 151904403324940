@import "mixins";

.carousel {
  border: none;
  padding: 0 15px;
  width: 960px;
  margin: 30px auto 0;
  max-width: 100%;
  .carousel-indicators{
    display: none;
  }
  @media (max-width: 640px) {
    padding: 0 20px 30px;
    margin: 20px auto 0;
    .carousel-indicators{
      display: block;    
      bottom: 0;
      z-index: 9;
      li{
        background-color: var(--color-line-01);    
        width: 15px;
        height: 15px;
        margin: 0 3px;
        &.active{
          background-color: var(--color-primary);    
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

  .carousel-control {
    display: none;
    width: 8%;
    width: 0px;
    >a {
      >span {
        color: var(--color-white);
        font-size: 29px !important;
      }
    }
  }
  .carousel-control.left {
    margin-right: 40px;
    margin-left: 32px;
    background-image: none;
    opacity: 1;
  }
  .carousel-control.right {
    margin-right: 40px;
    margin-left: 32px;
    background-image: none;
    opacity: 1;
  }
  .carousel-col {
    position: relative;
    min-height: 1px;
    padding: 5px;
    float: left;
  }
  .active {
    >div {
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
  .block {
    width: 100%;
  }
  @media (max-width: 640px) {
    .carousel-inner {
      .active.left {
        left: -100%;
      }
      .active.right {
        left: 100%;
      }
      .next {
        left: 100%;
      }
      .prev {
        left: -100%;
      }
    }
    .carousel-col {
      width: 100%;
    }
  }
  @media (min-width: 641px) and (max-width: 991px) {
    .carousel-inner {
      .active.left {
        left: -50%;
      }
      .active.right {
        left: 50%;
      }
      .next {
        left: 50%;
      }
      .prev {
        left: -50%;
      }
    }
    .carousel-col {
      width: 50%;
    }
    .active {
      >div {
        &:first-child {
          + {
            div {
              display: block;
            }
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    .carousel-inner {
      .active.left {
        left: -33.33%;
      }
      .active.right {
        left: 33.33%;
      }
      .next {
        left: 33.33%;
      }
      .prev {
        left: -33.33%;
      }
    }
    .carousel-col {
      width: 33.33%;
    }
    .active {
      >div {
        &:first-child {
          + {
            div {
              display: block;
              + {
                div {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  .carousel-slide{
    width: 100%;
    .single-slide{
      margin: auto;
      @media screen and (min-width: 576px) {
        max-width: 540px;
      }
      @media screen and (max-width: 640px) {
        max-width: 100%
      }
      @media screen and (min-width: 768px) {
        max-width: 720px;
      }
      @media screen and (min-width: 992px) {
        max-width: 960px;
      }
      @media screen and (min-width: 1200px) {
        max-width: 960px;
      }
      .slick-slide{
        position: relative;
        min-height: 1px;
        max-height: 374px;
        float: left;
      }
    }
    .multiple-slide{
      border: none;
      padding: 0 25px;
      width: 960px;
      margin: 30px auto 0;
      max-width: 100%;
      .slick-slide{
        position: relative;
        min-height: 1px;
        padding: 5px;
        float: left;
      }
    }
    .slick-track{
        display: flex !important;
    }
    .slick-slide {
      height: inherit !important;
      div{
        height: 100%;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .slick-initialized {
      .slick-slide{
        width: 960px;
      }
    }
    .slick-next, .slick-prev {
      width: 50px;
      height: 50px;
      z-index: 99;
      &:before{
        opacity: 1;
        position: absolute;
        top: 0;
        display: block;
        width: 50px;
        height: 50px;
        background: var(--color-background-01);
        border-radius: 50%;
        line-height: 50px;
        @media screen and (max-width: 767px) {
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
      }
      img {
        opacity: 1;
        position: absolute;
        top: 18px;
        display: block;
        width: 16px;
        height: 16px;
        left: 17px;
        @media screen and (max-width: 767px) {
          width: 16px;
          height: 16px;
          line-height: 40px;
          top: 13px;
          left: 12px;
        }
    }
      
      @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
        top: calc(50% - 20px);
      }
    }
    .slick-next{
      right: calc((100% - 960px)/2 - 70px);
      &:before{
        transform: rotate(180deg);
        line-height: 47px;
      }
      img {
        transform: rotate(180deg);
      }
      @media screen and (max-width: 1200px) {
        right: 50px;
      }
      @media screen and (max-width: 767px) {
        right: 15px;
        &:before{
          line-height: 37px;
        }
      }
    }
    .slick-prev{
      left: calc((100% - 960px)/2 - 70px);
      @media screen and (max-width: 1200px) {
        left: 50px;
      }
      @media screen and (max-width: 767px) {
        left: 15px;
      }
    }
    .slick-slider{
      position: relative;
      
      &:after, &:before{
        content: '';
        width: calc((100% - 960px)/2);
        height: 100%;
        background-color: rgba(255,255,255,0.7);
        position: absolute;
        top: 0;
        transition: opacity 0.2s;
        z-index: 9;
        display: none;
        
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
      &:before{
        right: 0;
      }
    }
    .slick-dots {
      width: 960px;
      height: 30px;
      max-width: 100%;
      text-align: center;
      margin: 0 auto;
      z-index: 9;
      position: relative;
      padding-top: 14px;
      flex-wrap: nowrap;
      display: flex !important;
      bottom: 0;
        @media screen and (max-width: 640px) {
          width: calc(100% - 20px);
          margin: 0 10px;
        }
      li {
        display: inline-block;
        width: 100%;
        height: 4px;
        margin: 0;
        background-color: var(--color-background-02);
        border-radius: 0;
        box-shadow: none;
        position: relative;
        &:hover {
          background-color: var(--color-primary);
          opacity: 1;
        }
        &.slick-active{
            background-color:var(--color-primary);
            opacity: 1;
        }
        button{
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          &:before{
            display: none;
          }
        }
      }
    }
  }

