@import "mixins";
.hero-banner{
  width: 100%;
  margin-bottom: 72px;
  .hero_banner {
   .carousel_wide {
     position: relative;
     width: 100%;
     height: 435px;
     overflow: hidden;
     text-align: center;
     display: flex;
     align-items: flex-end;
     ul {
       display: inline;
       float: left;
       overflow: hidden;
       &:after {
         display: block;
         clear: both;
         height: 0;
         visibility: hidden;
         content: ".";
       }
       li {
         display: inline;
         float: left;
         overflow: hidden;
         img {
           width: min(100%, 960px);
         }
       }
     }
   }
   .carousel_base {
     position: absolute;
     top: 0;
   }
   .carousel_wrap {
     position: absolute;
     top: 0;
     overflow: hidden;
   }
   .slider_prev {
     position: absolute;
     top: 0;
     z-index: 10;
     height: 100%;
     overflow: hidden;
     background-color: rgba(255, 255, 255, 0.7);
     cursor: pointer;
     transition: opacity 0.2s;
     left: 0;
     &:after {
       position: absolute;
       top: 50%;
       display: block;
       width: 40px;
       height: 40px;
       margin-top: -10px;
       right: 15px;
       background: var(--color-background-01);
       border-radius: 50%;
       line-height: 45px;
     }
     &:hover {
       opacity: 0.7;
     }
   }
   .slider_next {
     position: absolute;
     top: 0;
     z-index: 10;
     height: 100%;
     overflow: hidden;
     background-color: rgba(255, 255, 255, 0.7);
     cursor: pointer;
     transition: opacity 0.2s;
     right: 0;
     &:after {
       position: absolute;
       top: 50%;
       display: block;
       width: 40px;
       height: 40px;
       margin-top: -10px;
       left: 15px;
       background: var(--color-background-01);
       border-radius: 50%;
       line-height: 45px;
       transform: rotate(180deg);
     }
     &:hover {
       opacity: 0.7;
     }
   }
   .pagination {
     width: 960px;
     height: 30px;
     text-align: center;
     margin: 0 auto;
     z-index: 9;
     position: relative;
     padding-top: 24px;
     a {
       display: inline-block;
       width: 25%;
       height: 4px;
       margin: 0;
       background-color: var(--color-background-02);
       border-radius: 0;
       &:hover {
         background-color: var(--color-primary);
         opacity: 1;
       }
     }
     a.active {
       background-color:var(--color-primary);
       opacity: 1;
     }
   }
 }
}
@media screen and (max-width: 640px) {
	.hero-banner{
    margin-bottom: 48px;
    .hero_banner {
      .pagination {
          width: calc(100% - 30px);
          padding-top: 0;
          bottom: 0;
          position: absolute;
          left: 15px;
          height: 5px;
      }
      .slider_prev {
        width: 50px !important;
        background: none;
        &:after {
          width: 32px;
          height: 32px;
          right: 5px;
          line-height: 38px;
        }
      }
      .slider_next {
        width: 50px !important;
        background: none;
        &:after {
          width: 32px;
          height: 32px;
          left: 5px;
          line-height: 38px;
        }
      }
      .carousel_base {
        position: relative;
        left: 0 !important;
        width: 100% !important;
      }
      .carousel_wrap {
        position: relative;
      }
      .carousel_wide {
        height: auto;
        padding-bottom: 16px;
        ul {
          li {
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
            }
          }
        }
      }
      .carousel {
        .carousel-indicators {
          bottom: 20px;
          li {
            flex: 0 1 auto;
            box-sizing: content-box;
            width: 10px;
            height: 10px;
            margin-right: 0px;
            margin-left: 0px;
            background-color: var(--color-light-gray1);
            background-clip: padding-box;
            border-top: 0;
            border-bottom: 0;
            border-radius: 5px;
            cursor: pointer;
            + {
              li {
                margin-left: 15px;
              }
            }
          }
          .active {
            background-color: var(--color-normal-blue);
            opacity: 1;
          }
        }
        .carousel-control-prev {
          bottom: 10px;
          align-items: flex-end;
          width: 35px;
          opacity: 1;
          .slider-prev-icon {
            img {
              width: 10px;
              height: 20px;
            }
          }
          .slider-next-icon {
            img {
              width: 10px;
              height: 20px;
            }
          }
        }
        .carousel-control-next {
          bottom: 10px;
          align-items: flex-end;
          width: 35px;
          opacity: 1;
          .slider-prev-icon {
            img {
              width: 10px;
              height: 20px;
            }
          }
          .slider-next-icon {
            img {
              width: 10px;
              height: 20px;
            }
          }
        }
        .carousel-item {
          transition: transform 0.35s ease-in-out;
        }
      }
    }
  }
}

.hero-banner{
  .slick-track{
      display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
    div{
      height: 100%;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .slick-initialized {
    .slick-slide{
      width: 960px;
    }
  }
  .slick-next, .slick-prev {
    width: 50px;
    height: 50px;
    z-index: 99;
    &:before{
      opacity: 1;
      position: absolute;
      top: 0;
      display: block;
      width: 50px;
      height: 50px;
      background: var(--color-background-01);
      border-radius: 50%;
      line-height: 50px;
      @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
  }
  img {
    opacity: 1;
    position: absolute;
    top: 18px;
    display: block;
    width: 16px;
    height: 16px;
    left: 17px;
    @media screen and (max-width: 767px) {
      width: 16px;
      height: 16px;
      line-height: 40px;
      top: 13px;
      left: 12px;
    }
}
    
    @media screen and (max-width: 767px) {
      width: 40px;
      height: 40px;
      top: calc(50% - 20px);
    }
  }
  .slick-next{
    right: calc((100% - 960px)/2 - 70px);
    &:before{
      transform: rotate(180deg);
      line-height: 47px;
    }
    img {
      transform: rotate(180deg);
    }
    @media screen and (max-width: 1200px) {
      right: 50px;
    }
    @media screen and (max-width: 767px) {
      right: 15px;
      &:before{
        line-height: 37px;
      }
    }
  }
  .slick-prev{
    left: calc((100% - 960px)/2 - 70px);
    @media screen and (max-width: 1200px) {
      left: 50px;
    }
    @media screen and (max-width: 767px) {
      left: 15px;
    }
  }
  .slick-slider{
    position: relative;
    
    &:after, &:before{
      content: '';
      width: calc((100% - 960px)/2);
      height: 100%;
      background-color: rgba(255,255,255,0.7);
      position: absolute;
      top: 0;
      transition: opacity 0.2s;
      z-index: 9;
      
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
    &:before{
      right: 0;
    }
  }
  .slick-dots {
    width: 960px;
    height: 30px;
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    z-index: 9;
    position: relative;
    padding-top: 14px;
    flex-wrap: nowrap;
    display: flex !important;
    bottom: 0;
      @media screen and (max-width: 640px) {
        width: calc(100% - 20px);
        margin: 0 10px;
      }
    li {
      display: inline-block;
      width: 100%;
      height: 4px;
      margin: 0;
      background-color: var(--color-background-02);
      border-radius: 0;
      box-shadow: none;
      position: relative;
      &:hover {
        background-color: var(--color-primary);
        opacity: 1;
      }
      &.slick-active{
          background-color:var(--color-primary);
          opacity: 1;
      }
      button{
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        &:before{
          display: none;
        }
      }
    }
  }
}