@import "mixins";
.input-box {
  &.left-side-medium {
    display: inline-block;
    width: 350px;
    max-width: 100%;
    .input-form {
      @media screen and (max-width: 640px) {
        margin-bottom: 10px;
      }
      .input-container {
        .pull-down {
          width: 100%;
          select {
            width: 100%;
          }
        }
      }
    }
  }
}
.input-form {
  @extend %flexbox-left;
  margin-bottom: 32px;
  max-width: 100%;
  margin-left: 16px;
  margin-right: 16px;
  width: calc(100% - 32px);
  .input-form {
    margin-bottom: 0;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  &.disabled {
    .input-box {
      background: var(--color-background-05);
    }
  }
  &.inline-left-large {
    float: left;
    width: calc(70% - 32px);
    @media screen and (max-width: 640px) {
      width: 100%;
      float: none;
    }
    input {
      width: 100%;
    }
  }
  &.inline-left-small {
    float: left;
    width: 200px;
    @media screen and (max-width: 640px) {
      width: 100%;
      float: none;
    }
    input {
      width: 100%;
    }
  }
  &.inline-right-small {
    float: right;
    width: calc(30% - 32px);
    @media screen and (max-width: 640px) {
      width: 100%;
      float: none;
    }
    input {
      width: 100%;
    }
  }
  > label {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    span {
      &.required {
        @extend %required;
        margin-left: 8px;
      }
    }
    + .description-content {
      width: 100%;
      margin: 0 0 10px;
    }
  }
  &.S-14 {
    .input-box {
      &.multi-type {
        .description-content {
          margin-bottom: 5px;
          &.D-01 {
            margin-bottom: 20px;
          }
        }
        .input-box {
          .input-container {
            input {
              + label {
                &:before {
                  border: 1px solid var(--color-primary);
                }
              }
            }
          }
        }
      }
    }
  }

  &.S-04 {
    &.with-image {
      .benefits-image {
        background-color: var(--color-background-02);
        margin: 15px 0;
        min-height: 105px;
        width: 302px;
        border-radius: 4px;
        @extend %flexbox-center;
        img {
          max-width: 100%;
        }
      }
    }
  }

  &.S-17 {
    &.with-image {
      .radio {
        .benefits-image {
          background-color: var(--color-background-02);
          margin: 0 0 15px 20px;
          min-height: 105px;
          width: 302px;
          border-radius: 4px;
          @extend %flexbox-center;
          img {
            max-width: 100%;
          }
        }
      }

      .checkbox {
        .benefits-image {
          background-color: var(--color-background-02);
          margin: 0 0 15px 0;
          min-height: 105px;
          width: 302px;
          border-radius: 4px;
          @extend %flexbox-center;
          img {
            max-width: 100%;
          }
        }
      }
    }

    .input-box {
      margin-bottom: -1px;
      border-radius: 0;
      &.active {
        margin-bottom: 0;
        > div {
          display: block;
        }
      }
      &.disabled {
        background: var(--color-background-05);
      }
      &.radio-type {
        padding: 20px;
        .input-container {
          label {
            padding: 0 0 20px 30px;
            
          }
          input[type=radio] + label:before{
            left: 0px;
            top: 5px;
          }
        }

        &.active{
          .input-container {
            label::after {
              left: 5px;
              top: 10px;
            }
          }
        }
      }
      &.single-type {
        .input-container {
          label {
            padding: 0 0 20px 30px;
          }
        }
        .checkbox {
          padding: 0 0 0 30px;
          &.quantity-input {
            margin-bottom: 15px;
          }
        }
      }

      .error-message {
        padding: 0 0 20px 30px;
        color: var(--color-primary);
      }
      .product-sets {
        margin-bottom: 15px;
      }
      .productSet {
        padding: 15px 15px 0 15px;
        margin-left: 30px;
        border: 1px solid var(--color-line-01);
        .input-container {
          ul {
            list-style: disc;
            li::marker {
              color: var(--color-primary);
            }
          }
        }

        .input-container label {
          padding: 0 0 15px 30px;
        }
      }
      .productSet + .productSet {
        border-top: none;
      }
      .padding-indent-left {
        padding: 0 0 0 20px;
      }

      .radio {
        padding: 0 0 0 30px;
        .link_wrapper {
          padding: 0 0 15px 0;
          a {
            font-size: 14px;
          }
        }
        .description-text {
          font-weight: 500;
          font-size: 14px;
          // padding: 0 0 15px 20px;
        }
      }
      .checkbox {
        padding: 20px 20px 0 30px;
        .movement {
          padding: 0 0 0 30px;
        }
        .link_wrapper {
          padding: 0 0 15px 0;
          a {
            font-size: 14px;
          }
        }
        .description-text {
          font-weight: 500;
          font-size: 14px;
          padding: 0 0 15px 0;
        }
      }

      .input-container {
        label {
          margin-bottom: 0;
          padding: 20px 20px 15px 50px;
          span {
            &.right {
              width: 160px;
            }
          }
        }
        .description-text {
          font-weight: 500;
          font-size: 14px;
          margin: 10px 0 10px 0;
        }
        .price {
          margin: 0 5px 0 5px;
          font-size: 18px;
          input[type="text"].small {
            width: 70px;
            padding: 3px 0 3px 0;
            text-align: center;
            color: #f76b1f;
            font-weight: bold;
            font-size: 18px;
          }
        }
        .price-label {
          font-size: 10.5px;
        }
      }
    }
  }

  &.S-17-dropdown {
    .input-box {
      padding: 0px;
      border: 0px solid #d0d0d0;
    }
    .input-container select {
      padding: 5px 35px 5px 20px;
      text-align: center;
    }
  }

  &.S-17-1 {
    .input-box {
      padding: 0 0 20px 20px;
      border: 0px solid #d0d0d0;

      &.remove-padding {
        padding: 0;
      }
      &.single-type {
      }

      .input-container {
        .left {
          width: 40%;
        }
        .right {
          width: 60%;
        }

        .description-text {
          font-weight: 500;
          font-size: 14px;
        }
        .price {
          margin: 0 5px 0 5px;
          font-size: 18px;
        }
        .price-label {
          font-size: 11px;
        }
      }
    }
  }
  &.S-18 {
    &.with-image {
      .benefits-image {
        background-color: var(--color-background-02);
        min-height: 105px;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 20px;
        @extend %flexbox-center;
        img {
          max-width: 100%;
          width: 100%;
        }

        &.mb-0 {
          margin-bottom: 0;
        }
      }
    }
    .right{
      &.mobile{
        width: 100%;
      }
    }
    .input-container {
      label {
        &::before {
          left: 18px;
          top: 23px;
        }
        &::after {
          left: 23px;
          top: 20px;
        }
        &.disabled {
          &::before {
            opacity: 0.5;
            cursor: not-allowed;
            border-color: var(--color-line-01);
          }
        }
        
      }

      .input-form {
        margin: 0;
        &.S-04 {
          width: 100%;
        }
        &.payment-type {
          label {
            padding-left: 30px;
            &::before {
              left: 0;
              top: 28px;
            }
            &::after {
              left: 5px;
              top: 24px;
            }
            .right{
              width: auto;
            }
          }
        }
        
        label {
          .right {
            width: 100%;
            p {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .description {
      margin-bottom: 10px;
      width: 100%;
      font-weight: normal;
      a {
        z-index: 2;
        color: var(--color-accent-03);
        position: relative;
      }
    }

    .input-box {
      width: 100%;
      &.multi-type {
        width: 900px;
      }
    }
    .child-checkbox {
      &::before {
        border: 1px solid var(--color-primary) !important;
        left: 15px;
        top: 25px;
      }
      &::after {
        left: 20px;
        top: 22px;
      }
    }
    .pull-down {
      z-index: 2;
      select {
        width: 150px;
        margin-right: 20px;
      }
    }
    &.no-margin {
      margin: 0;
    }
  }

  &.S-15 {
    &.with-image {
      .benefits-image {
        background-color: var(--color-background-02);
        margin: 15px 0;
        min-height: 105px;
        width: 100%;
        border-radius: 4px;
        @extend %flexbox-center;
        img {
          max-width: 100%;
        }
      }
    }
    .input-box {
      .input-container {
        .description-text {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  &.S-11 {
    .input-container {
      .input-box {
        border: none;
        padding: 6px 0 0 30px;
      }
    }
  }
  &.S-01 {
    textarea {
      margin-top: 10px;
      z-index: 2;
    }
  }
  &.checkbox-list-wrapper {
    .input-box {
      padding: 16px 16px 25px 16px;
      background-color: var(--color-background-02);
      margin-top: 20px;
      margin-bottom: 20px;
      border: none;

      .list-title {
        text-align: center;
        padding: 0 20px 15px 20px;
        border-bottom: 2px solid var(--color-line-01);

        span {
          font-weight: 700;
          vertical-align: top;
          font-size: 16px;
        }
      }

      .description-content {
        margin: 15px 20px;
      }

      .checkbox-list {
        margin: 0 20px;
        .input-container {
          border-bottom: 1px solid var(--color-line-01);
          padding: 10px 0;

          label {
            padding: 0 0 0 25px;
            font-weight: normal;
            font-size: 16px;
          }
        }
      }
    }
  }
  .input-container {
    textarea {
      @extend %fontYuGothic;
    }
    .description-content {
      width: 100%;
      margin: 0 0 10px;
    }
    label {
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      @extend %fontNotoSans;
      display: flex;
      align-items: flex-start;
      span {
        @extend %fontYuGothic;
        &.required {
          margin-left: 8px;
        }
      }
    }
    span {
      &:not(.required) {
        margin: 0 8px;
        font-size: 16px;
      }
    }
    .pull-down {
      position: relative;
      &.disabled {
        pointer-events: none;
        &.calendar {
          .react-datepicker__input-container {
            input {
              background-color: var(--color-background-05);
            }
          }
        }
      }
      &.calendar {
        .react-datepicker__input-container {
          height: 100%;
          input {
            outline: none;
            padding: 0;
            height: calc(100% - 2px);
            font-size: 0;
            cursor: pointer;
            border: none;
            border-radius: 4px 0 0 4px;
            border-right: none;
            margin: 1px;
            &:active,
            &:focus {
              outline: none;
            }
          }
        }
        .react-datepicker__current-month {
          display: none;
        }
        .react-datepicker__navigation {
          top: 10px;
        }
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name,
        .react-datepicker__day,
        .react-datepicker__month-text,
        .react-datepicker__quarter-text,
        .react-datepicker__year-text {
          color: var(--color-primary-text);
          line-height: 22px;
          font-size: 12px;
          width: 22px;
          margin: 3px;
          &:not(.react-datepicker__day--disabled) {
            &:hover {
              background-color: var(--color-primary);
              color: var(--color-white);
            }
          }
          &.react-datepicker__day--disabled {
            background: var(--color-background-02);
            border-radius: 4px;
            color: var(--color-line-02);
          }
        }
        .react-datepicker__day--selected {
          background-color: var(--color-primary);
          color: var(--color-white);
        }
        .react-datepicker__month {
          /*margin: 5px;*/
          margin: 5px 5px 5px 0;
        }
        .react-datepicker__month-dropdown-container,
        .react-datepicker__year-dropdown-container {
          margin: 0 3px;
          select {
            &.react-datepicker__month-select,
            &.react-datepicker__year-select {
              width: auto;
              padding: 5px;
              background-position: calc(100% - 0px) center;
              background-size: 13px;
              border: none;
              font-size: 14px;
              font-weight: 700;
            }
            &.react-datepicker__year-select {
              width: 50px;
            }
          }
        }
        label {
          position: absolute;
          left: 45px;
          width: calc(100% - 80px);
          height: calc(100% - 2px);
          font-size: 14px;
          font-weight: normal;
          line-height: 40px;
          margin: 0;
          padding-right: 35px;
          overflow: hidden;
          background: #fff;
          z-index: 99;
          top: 1px;
          pointer-events: none;
        }
        .react-datepicker-wrapper {
          position: absolute;
          z-index: 1;
          width: 45px;
          height: 100%;
        }
        @media screen and (max-width: 640px) {
          width: 100%;
          select {
            width: 100%;
          }
        }
      }
      + input {
        margin-left: 16px;
      }
    }
    &.email {
      .large {
        width: calc(100% - 355px);
        min-width: 180px;
      }
      .medium {
        width: 100%;
        max-width: 240px;
      }
    }
  }
  .receipt {
    //margin-top: 20px;
    @extend %flexbox-center;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    dt {
      background-color: var(--color-primary);
      color: var(--color-white);
      border-radius: 4px 0 0 4px;
      min-width: 176px;
      text-align: center;
      padding: 20px;
    }
    dd {
      background-color: var(--color-background-02);
      border-radius: 0 4px 4px 0;
      min-width: 185px;
      padding: 20px;
    }
  }
  .input-box {
    width: 564px;
    max-width: 100%;
    border: 1px solid var(--color-line-01);
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &.active {
      border: 1px solid var(--color-primary);
      background-color: var(--color-accent-05);
      + .active {
        border-top: none;
      }
    }

    .input-container {
      position: relative;
      label {
        margin-bottom: 0;
        padding: 20px 20px 20px 50px;
        flex-wrap: wrap;
        display: flex;
        font-weight: bold;
        align-items: center;
        > p,
        dl {
          width: calc(100% - 155px);
          position: relative;
          padding-right: 10px;
          + textarea {
            margin-top: 10px;
          }
          dt {
            display: inline-block;
          }
          dd {
            &.tool-tip {
              position: relative;
              margin-left: 15px;
              display: inline-block;
              i {
                color: var(--color-line-01);
                line-height: 22px;
                font-size: 16px;
                z-index: 1;
                position: relative;
                &:hover {
                  & + .information {
                    display: block;
                  }
                }
              }
              .information {
                display: none;
                position: absolute;
                z-index: 9;
                font-weight: 500;
                box-shadow: 0px 0px 3px 1px var(--color-line-01);
                padding: 10px;
                width: 300px;
                background: var(--color-white);
                border-radius: 5px;
                margin-left: -15px;
                margin-top: 5px;
                font-size: 12px;
                &:before,
                &:after {
                  content: "";
                  width: 0;
                  height: 0;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  border-bottom: 5px solid var(--color-line-01);
                  position: absolute;
                  top: -5px;
                  left: 15px;
                }
                &:before {
                  border-bottom: 5px solid var(--color-white);
                  z-index: 9;
                  top: -4px;
                }
              }
            }
          }
        }
        span {
          &:not(.right) {
            margin: 0;
            font-weight: 500;
            width: 100%;
          }
          &.right {
            font-weight: 500;
            font-size: 12px;
            line-height: 25px;
            margin-right: 0;
            margin-left: auto;
            width: 155px;
            text-align: right;
            word-break: break-word;
            &.absolute-price {
              position: absolute;
              z-index: 8;
              right: 0;
              width: 200px;
              @media screen and (max-width: 640px) {
                position: relative;
                right: auto;
              }
            }
            & + p {
              width: 302px;
              font-weight: 500;
              font-size: 16px;
              @extend %fontYuGothic;
            }
            p {
              font-size: 24px;
              color: var(--color-primary);
              display: inline-block;
              @extend %fontNotoSans;
              font-weight: bold;
              &.smaller {
                font-size: 16px;
              }
            }
            &.mobile{
              display: none;
            }
          }
          a {
            position: relative;
            z-index: 1;
            text-decoration: underline;
          }
        }

        .M-02 {
          padding-left: 0;
          span {
            display: block;
            width: auto;
            margin: 0 8px;
          }
        }
      }
      input {
        &[type="checkbox"] {
          &.disabled {
            & + label {
              &:before {
                border-color: var(--color-line-01);
                // background-color: rgb(165, 165, 165);
              }
            }
          }
        }
        &[type="radio"] {
          opacity: 0.01;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          &:checked {
            & + label {
              &:after {
                content: "";
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: var(--color-primary);
                left: 23px;
                top: 28px;
              }
            }
          }
          &.disabled {
            & + label {
              &:before {
                border: 1px solid var(--color-line-01);
              }
              &:after {
                background: var(--color-line-01);
              }
            }
          }
          & + label {
            &:before {
              content: "";
              position: absolute;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 1px solid var(--color-primary);
              left: 18px;
              top: 23px;
            }
          }
        }
      }
    }

    &.plan {
      border: 1px solid var(--color-primary);
      padding-top: 10px;
      .tag {
        position: absolute;
        top: -15px;
        left: 15px;
        p {
          display: -webkit-box;
        }
      }
      &.active {
        background-color: transparent;
      }
      .input-container {
        @extend %flexbox-center;
        label {
          padding: 20px;
          > p {
            &:last-child {
              width: 100%;
              margin-top: 10px;
              padding: 0;
            }
          }
        }
        .input-box-container {
          display: table;
          width: 100%;
          .input-box {
            display: table-cell;
          }
        }
        .input-box {
          border: 1px solid var(--color-primary);
          width: 50%;
          border-bottom: none;
          border-left: none;
          border-radius: 0;

          &.active {
            background-color: var(--color-accent-05);
            // border-right: none;
            .input-container {
              border: 2px solid var(--color-primary);
            }
          }

          .input-container {
            border: 2px solid transparent;
          }

          &:last-child {
            border-right: none;
          }
          label {
            padding: 10px 10px 10px 30px;
            font-size: 14px;
            font-weight: 500;
            position: relative;
            display: block;
            text-align: center;
            width: auto;
          }
          input {
            &[type="radio"] {
              & + label {
                &:before {
                  left: 5px;
                  top: 13px;
                }
              }
              &:checked {
                & + label {
                  &:after {
                    left: 10px;
                    top: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.single-type {
      padding: 20px;
      > .input-container {
        > label {
          padding: 0 0 0 30px;
        }
      }
    }
    &.multi-type {
      padding: 24px;
      > .input-container {
        > label {
          padding: 0 0 20px 30px;
        }
      }
      .description-content {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
      .input-box {
        border-top: none;
        background-color: var(--color-background-01);
        margin-bottom: 0;
        &:only-child {
          border-radius: 4px;
        }
        &:nth-of-type(1) {
          border-top: 1px solid var(--color-line-01);
          border-radius: 4px 4px 0 0;
        }
        &:nth-last-of-type(1) {
          border-radius: 0 0 4px 4px;
        }
        &.active {
          border: 2px solid var(--color-primary);
          .input-container {
            input {
              & + label {
                &:before {
                  border: 1px solid var(--color-primary);
                }
              }
            }
          }
        }
        .input-container {
          input {
            & + label {
              &:before {
                border: 1px solid var(--color-line-01);
              }
            }
          }
        }
      }
    }
    &.with-tag {
      .input-container {
        label {
          line-height: 26px;
          span {
            flex: 1;
            flex-basis: 100%;
            &:not(.tag) {
              margin-top: 3px;
            }
          }
        }
        input {
          &[type="radio"] {
            & + label {
              &:before {
                top: 60px;
              }
            }
            &:checked {
              & + label {
                &:after {
                  top: 65px;
                }
              }
            }
          }
        }
      }
    }
  }

  .pull-down-arrow {
    width: 100%;
    margin-right: 25px;
    margin-top: 20px;
    .input-box {
      margin: auto;
    }
    .arrow-down {
      margin-top: 20px;
      text-align: center;
    }
  }
  &.illustration {
    display: table;
    border-spacing: 20px 0;
    .input-box {
      width: 270px;
      min-height: 180px;
      display: table-cell;
      .input-container {
        height: 100%;
        input[type="radio"] {
          + label {
            &:before {
              left: 15px;
              top: 15px;
            }
          }
          &:checked {
            + label {
              &:after {
                left: 20px;
                top: 20px;
              }
            }
          }
        }
        label {
          justify-content: center;
          padding: 20px;
          img {
            height: 70px;
            margin-top: 20px;
          }
          span {
            font-weight: 700;
            text-align: center;
            width: 100%;
            margin-top: 20px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .description-text-bottom {
    width: 100%;
    a {
      color: var(--color-accent-03);
    }
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    &.illustration {
      display: flex;
      .input-box {
        height: 160px;
        width: calc(50% - 10px);
        margin-bottom: 0;
        &:nth-child(2n) {
          margin-left: 20px;
        }
        label {
          img {
            height: 60px;
          }
        }
      }
    }
    .desktop {
      display: none;
    }
    .input-container {
      .pull-down {
        select {
          width: 100px;
        }
        + input {
          margin-left: 10px;
          width: calc(100% - 110px);
        }
      }
      &.email {
        input {
          margin-bottom: 16px;
          &.large {
            width: calc(100% - 23px);
            margin: 0;
          }
        }
        span {
          + input {
            width: calc(100% - 25px);
          }
        }
      }
      span {
        &:not(.required) {
          font-size: 14px;
          margin: 0 5px;
        }
      }
      .pull-down {
        
        &:nth-of-type(2n) {
          margin: 0 10px;
        }
      }
    }
    .input-container.attr-dropdown {
      .pull-down {
        width: 100%;
        select {
          width: 320px;
        }
      }
    }
    .receipt {
      dt {
        padding: 20px 10px;
        min-width: 120px;
      }
      dd {
        padding: 20px 10px;
        min-width: 120px;
      }
    }
    .input-box {
      .input-container {
        label {
          > p,
          dl {
            width: auto;
            padding-right: 25px;
            position: relative;
            margin-bottom: 4px;
            display:flex;
            align-items: center;
            dd {
              &.tool-tip {
                .information {
                  right: -10px;
                  width: 220px;
                  &:before,
                  &:after {
                    right: 10px;
                    left: auto;
                  }
                }
              }
            }
          }
          padding: 15px 15px 15px 45px;
          span {
            &.right {
              width: 100%;
              text-align: right;
              margin-top: 5px;

              & + p {
                font-size: 14px;
                width: 100%;
                padding: 0;
                margin-bottom: 0;
              }
              p {
                &.smaller {
                  font-size: 14px;
                }
              }
              &.mobile{
                display: block;
              }
            }
          }
        }

        input {
          &[type="radio"] {
            & + label {
              &:before {
                left: 10px;
                top: 24px;
              }
            }
            &:checked {
              & + label {
                &:after {
                  left: 15px;
                  top: 29px;
                }
              }
            }
          }
        }
      }

      &.plan {
        .input-container {
          justify-content: left;
          label {
            padding: 15px;
          }
          .input-box-container {
            .input-box {
              width: 100%;
              border-right: none;
              display: flex;
              padding-left: 8px;
            }
          }
        }
        .tag {
          // padding: 5px 15px;
          font-size: 12px;
          top: -15px;
        }
      }
      &.multi-type {
        padding: 15px;
        > .input-container {
          > label {
            padding: 0 0 10px 30px;
          }
        }
      }
      &.with-tag {
        .input-container {
          input {
            &[type="radio"] {
              & + label {
                &:before {
                  top: 50px;
                }
              }
              &:checked {
                & + label {
                  &:after {
                    top: 55px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.form_container {
  margin: 0 auto;
  width: 895px;
  max-width: 100%;
  @extend %flexbox-left;
  align-items: flex-start;
  .estimate_wrapper {
    margin-left: auto;
  }
  .form_detail {
    max-width: 100%;
    width: calc(100% - 300px);
    padding-right: 30px;

    .alert-message {
      margin-top: 0;
    }
    &.full-width {
      width: 100%;
      padding: 0;

      .form_wrapper-input {
        margin-bottom: 48px;
        .input-with-button {
          display: flex;
          margin-bottom: 10px;
          .input-box {
            margin-right: 20px;
          }
          .btn_wrapper {
            .btn {
              margin-bottom: 0;
            }
          }
          @media screen and (max-width: 640px) {
            flex-wrap: wrap;
            margin-bottom: 15px;
          }
        }
        @media screen and (max-width: 640px) {
          margin-bottom: 32px;
        }
        .description-container {
          margin-bottom: 20px;
          .description-content {
            display: inline-block;
            margin-bottom: 0;
            &.D-02 {
              span {
                font-size: 20px;
              }
            }
          }

          @media screen and (max-width: 640px) {
            margin-bottom: 0;
            .description-content {
              margin-bottom: 15px;
              width: 100%;
              &.D-02 {
                span {
                  font-size: 18px;
                }
              }
            }
          }
        }
        .alert-message {
          margin-left: 16px;
          margin-right: 16px;
          width: calc(100% - 32px);
          @media screen and (max-width: 640px) {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
        }
        > p {
          max-width: 895px;
          margin-left: auto;
          margin-right: auto;
          @extend %flexbox-left;
          &.a-link {
            margin-top: 10px;
            color: var(--color-primary);
            a {
              color: var(--color-primary);
              text-decoration: underline;
            }
            i {
              margin-left: 10px;
              font-size: 10px;
            }
          }
        }
        .btn_wrapper {
          &.consent {
            margin: 20px auto 32px;
            @extend %flexbox-center;
          }
        }
        .input-form {
          //margin: auto;

          &.medium {
            width: 600px;
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            &.illustration {
              width: 640px;
            }
          }
          &.input-flex {
            display: flex;
            justify-content: left;
            flex-wrap: nowrap;
            .input-container {
              width: auto;
            }
          }
          &:not(.illustration) {
            .input-box {
              width: 100%;
            }
          }
          .input-container {
            width: 100%;

            > p {
              margin-top: 5px;
              width: 100%;
              margin-bottom: 5px;
              a {
                text-decoration: underline;
              }
            }
            > label {
              + p {
                margin-top: 0;
                margin-bottom: 10px;
              }
            }
            textarea {
              width: 100%;
            }
          }
        }
        .search_wrapper {
          margin: 20px auto;
        }
        .decoration-content {
          margin: 0 16px 32px;
          @media screen and (max-width: 640px) {
            margin: 0 0 32px;
          }
          &.service {
            margin-bottom: 20px;
          }
          &:not(.amount, .individual, .terms, .procedure) {
            &.instruction {
              margin: 20px auto;
              p {
                margin: 0;
              }
            }
            .input-box {
              border-top: 1px solid var(--color-line-01);
              margin-top: 0;
              padding-top: 20px;
              .input-form {
                justify-content: left;
                margin: 0;
              }
            }
            p {
              margin-top: 20px;
              a {
                color: var(--color-primary);
                text-decoration: underline;
              }
            }
          }
          &.terms {
            .btn_wrapper {
              &.consent {
                margin-bottom: 0;
              }
            }
          }
          &.individual {
            &.no-border {
              margin-bottom: 0;
              .information {
                padding: 0;
                ul {
                  li {
                    margin-bottom: 24px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &:not(.full-width) {
      .form_wrapper {
        .input-form,
        .description-content,
        .list_wrapper {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
      }
    }
    &.no-text-ident {
      .form_wrapper {
        .description-content,
        .link_wrapper {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
      }
    }
    .form_wrapper {
      width: 100%;
      .list_wrapper {
        margin-left: 16px;
        margin-right: 16px;
        width: calc(100% - 32px);
        .input-box {
          .input-form {
            width: 100%;
            margin: 0 auto 24px;
            max-width: 100%;
            justify-content: center;
          }
        }
      }
      .list-button-container {
        .list_wrapper {
          margin-left: 0;
          margin-right: 0;
          width: 50%;
          @media screen and (max-width: 640px) {
            width: 100%;
          }
        }
      }
      &:last-child {
        .btn_container {
          .btn_wrapper {
            button {
              margin-bottom: 0;
            }
          }
        }
      }

      .accordion {
        .list_wrapper {
          &.note {
            &:last-child {
              margin-bottom: 24px;
            }
          }
        }
      }
      .decoration-content {
        &.benefits {
          width: 100%;
        }
      }

      .additional-information {
        padding: 20px 20px 24px;
        border-radius: 4px;
        border: 1px solid var(--color-line-01);
        margin-left: 16px;
        margin-right: 16px;
        width: calc(100% - 32px);
        margin-bottom: 40px;
        .list-button-container {
          align-items: center;
          &:last-child {
            padding-bottom: 0;
          }
        }
        @media screen and (max-width: 640px) {
          border: none;
          padding: 0;
          margin-left: 0;
          margin-right: 0;
          width: 100%;
          &.no-border {
            border: none;
          }
          &.no-padding {
            padding: 0;
          }
        }
        .description-content {
          text-align: center;
          margin: auto;
          @media screen and (max-width: 640px) {
            &.D-02 {
              text-align: left;
            }
          }
        }
        .L-08 {
          margin: 24px 0;
          padding-top: 24px;
          border-top: 1px solid var(--color-line-01);
          border-radius: 0;
          @media screen and (min-width: 641px) {
            font-size: 16px;
          }
          &.no-border {
            border: none;
          }
          @media screen and (max-width: 640px) {
            margin: 32px 0;
          }
        }
        .list-button-container {
          .list_wrapper {
            border-top: none;
            margin: 0;
            padding-top: 0;
            width: 50%;
            @media screen and (max-width: 640px) {
              width: 100%;
            }
          }
        }
        > .btn_container {
          margin-bottom: 24px;
        }
      }
      > p {
        margin-bottom: 15px;
      }
      &-input {
        margin-bottom: 48px;
        .side-label-input {
          &.cpad-side {
            margin-bottom: 9px;
            @media screen and (max-width: 640px) {
              margin-bottom: 32px;
            }
            .input-form {
              margin-bottom: 15px;
              .input-container {
                width: 100%;
                .x-large {
                  width: calc(100% - 170px);
                  @media screen and (max-width: 640px) {
                    width: 100%;
                    margin-right: 0;
                  }
                }
                input[type="text"] {
                  + input {
                    @media screen and (max-width: 640px) {
                      margin-top: 15px;
                    }
                  }
                }
              }
            }
          }
        }
        > p {
          margin-bottom: 20px;
          .big {
            font-size: 20px;
          }
        }

        /*.heading_wrapper{
          margin-top: 25px;
        }*/
        .list_wrapper {
          margin-bottom: 32px;
          &.address {
            padding: 24px;
            box-shadow: 0 0 10px -5px var(--color-black);
            border-radius: 8px;
            .content-title {
              margin-bottom: 15px;
              h5 {
                border-bottom: 1px solid var(--color-line-01);
              }
            }
            .I-02 {
              .input-form {
                margin-bottom: 10px;
              }
            }
            .M-01 {
              .pull-down,
              select {
                width: 100%;
                background-color: var(--color-background-01);
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          + p {
            margin-top: 0;
          }
          &.option-result-box {
            padding: 24px;
            box-shadow: 0 0 10px -5px var(--color-black);
            max-width: 100%;
            z-index: 2;
            .option-result-container {
              border-bottom: 1px solid var(--color-line-01);
              margin-bottom: 30px;
              &:last-child {
                padding: 0;
                margin: 0;
                border: none;
              }
            }
            .btn_wrapper {
              width: 100%;
              @media screen and (max-width: 640px) {
                .btn_default {
                  width: 100%;
                }
              }
            }
            .input-form,
            .btn_wrapper {
              width: calc(100% - 32px);
              margin-left: auto;
              margin-right: auto;
              display: block;
            }
            .note-title {
              padding-left: 20px;
              position: relative;
              i {
                color: var(--color-primary);
                margin-right: 3px;
                position: absolute;
                left: 0;
              }
            }
            ul {
              margin-top: 5px;
              padding: 0 23px;
              li {
                margin-top: 5px;
                padding: 0 0 0 7px;
                border: none;
                &:before {
                  background-color: var(--color-primary-text);
                  top: 10px;
                }
              }
            }
            .input-box {
              border: none;
              label {
                padding: 0;
                margin-bottom: 10px;
                span {
                  width: auto;
                  font-weight: 700;
                  line-height: inherit;
                  margin-left: 12px;
                }
              }
            }
          }
        }
        .accordion {
          margin-bottom: 20px;
        }
        .input-form {
          &.no-margin {
            .input-form {
              width: 100%;
              margin-bottom: 0;
            }
            > label {
              @extend %fontNotoSans;
              width: 100%;
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 10px;
              + .input-box {
                margin-bottom: 0;
              }
              span {
                &.required {
                  margin-left: 12px;
                  border-radius: 12px;
                  background-color: var(--color-primary);
                  font-size: 12px;
                  padding: 2px 10px;
                  color: var(--color-white);
                }
              }
            }
            > .input-box {
              margin: 0;
              &:not(:last-child) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              }
              &:not(:first-of-type) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
              }
              &:not(.active) {
                &:not(:last-child) {
                  border-bottom: none;
                }
              }
            }
          }
        }
        .input-box {
          //margin-top: 20px;
          &.multi-type {
            .input-box {
              margin-bottom: 0;
            }
          }
          .tag {
            &.with-icon {
              position: absolute;
              right: 0;
              top: -20px;
            }
          }
        }
      }
      .btn_container {
        margin: 0 auto 40px;
        display: flex;
        text-align: center;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
        .btn_wrapper {
          + .btn_wrapper {
            margin-left: 24px;
          }
          button {
            margin-bottom: 48px;
          }
        }
      }

      .M-01 {
        &.show-input {
          .pull-down {
            width: 100%;

            & > select {
              width: calc(100% - 336px);
            }
          }
        }
      }

      & > *:last-child {
        margin-bottom: 0;
      }

      .modal_btn_container {
        .primary {
          .btn {
            min-width: unset;
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    margin: 10px auto 0;

    .estimate_wrapper {
      margin: auto;
      width: 100%;
      padding: 0 15px;
      .sp-display {
        display: flex;
        position: absolute;
        padding: 10px 0 10px;
        background: var(--color-background-01);
        bottom: 0;
        left: 0;
        width: calc(100% + 30px);
      }
    }
    .form_detail {
      width: 100%;
      padding-right: 0;
      .form_wrapper {
        padding: 0 15px;
        &-input {
          margin-bottom: 30px;
          .list_wrapper {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            &.address {
              padding: 20px 15px;
            }
            &.option-result-box {
              padding: 15px;
              .btn_wrapper {
                margin-bottom: 24px;
                width: 100%;
              }
              .input-form,
              .btn_wrapper {
                width: 100%;
              }
              .description-content {
                margin-bottom: 25px;
              }
              .input-form {
                .input-container {
                  input {
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
          /*.heading_wrapper{
            margin-top: 15px;
          }*/
          /*.input-box{
            margin-top: 15px;
          }*/
          > p {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .big {
              width: 100%;
              margin-bottom: 10px;
            }
          }
        }
        .btn_container {
          .btn_wrapper {
            width: 100%;
            .btn {
              width: 100%;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}

.box-container {
  width: 520px;
  max-width: 100%;
  border: 1px solid var(--color-line-01);
  margin: 0 auto 32px;
  text-align: center;
  border-radius: 4px;
  padding: 24px;
  .description-content {
    margin-bottom: 24px;
  }
  .btn_wrapper {
    margin-right: 24px;
    .btn {
      margin-bottom: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 640px) {
    padding: 20px 0;
    border: none;
    .btn_wrapper {
      margin-right: 10px;
    }
  }
}

.list-button-container {
  @extend %flexbox-left;
  border-top: 1px solid var(--color-line-01);
  padding: 24px 0;
  &.with-left-right-margin {
    margin-left: 16px;
    margin-right: 16px;
  }
  @media screen and (max-width: 640px) {
    &.with-left-right-margin {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
  .list_wrapper {
    width: 50%;
    margin-left: 0;
    margin-bottom: 0;
    span {
      p {
        font-size: 16px;
        @media screen and (max-width: 640px) {
          font-size: 14px;
        }
      }
    }
  }
  .btn_wrapper {
    width: 50%;
    text-align: right;
    .btn {
      min-width: 220px;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .list_wrapper {
      width: 100%;
    }
    .btn_wrapper {
      width: 100%;
      margin-top: 15px;
      text-align: center;
      .btn {
        width: 540px;
        max-width: 100%;
      }
    }
  }
}

.myDatePicker {
  width: 100%;
  z-index: 10;
  * {
    width: 80%;
  }

  & > div {
    width: 100%;
  }

  .react-datepicker {
    &__day-name,
    &__day {
      width: 10.9em;
    }
    &__input-container {
      width: 100%;
    }
    &__header,
    &__month-container,
    &__day-names,
    &__month,
    &__week {
      width: 100%;
    }
    &__current-month {
      &__current-month--hasYearDropdown {
        &__current-month--hasMonthDropdown {
          width: 100%;
        }
      }
    }
    &__header__dropdown {
      &--select {
        width: 100%;
      }
    }

    &__month-dropdown-container,
    &__year-dropdown-container {
      &--select {
        width: auto;
      }
    }

    &__navigation {
      width: 32px;
      &-icon {
        width: 0;
      }
    }
  }
}

.input-form {
  .input-container {
    .pull-down.calendar {
      img {
        &.custom-calendar-icon {
          position: absolute;
          top: 9px;
          left: 12px;
          z-index: 9;
          pointer-events: none;
        }
      }

      input {
        padding: 10px 15px 10px 50px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        width: 320px;
        max-width: 100%;
        border: 1px solid #d0d0d0;
        border: 1px solid var(--color-line-01);
        font-size: 14px;
        color: #3f3e3e;
        color: var(--color-secondary-text);
        border-radius: 4px;

        &:active,
        &:focus {
          outline: none;
          border: 1px solid var(--color-primary);
        }
      }

      .react-datepicker {
        &__day-name,
        &__day,
        &__time-name,
        &__month-text,
        &__quarter-text,
        &__year-text {
          width: 11%;
        }
      }
    }
  }
}

.react-datepicker-popper[data-placement^="bottom"] {
  .react-datepicker__triangle {
    margin-left: 20px;
  }
}
