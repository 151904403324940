@import "mixins";
.form_container {
  &.cpad_wrapper,
  &.cpad_wrapper2 {
    width: 865px;
    .form_detail {
      &.full-width {
        .form_wrapper-input {
          .decoration-content {
            &.individual {
              &.no-border {
                .information {
                  ul {
                    li {
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .form_wrapper-input {
      .multiple-column-input {
        &.two {
          .input-box {
            width: 50%;
            .input-form input {
              text-align: right;

              &:last-child {
                text-align: right;
              }
            }
          }
        }
        &.three {
          .input-box {
            width: 33%;
          }
        }
        &.text-right {
          input {
            text-align: right;
          }
        }
        @media screen and (max-width: 640px) {
          &.two {
            .input-box {
              width: 100%;
            }
          }
          &.three {
            .input-box {
              width: 100%;
            }
          }
        }
      }
      .one-column-input {
        .input-box {
          .input-form {
            input {
              &::placeholder {
                color: var(--color-line-01);
              }
              &:last-child {
                text-align: right;
              }
            }
          }
        }
        .btn_wrapper {
          padding-left: 237px;
        }
      }
      .side-label-input {
        .input-container {
          display: flex;
          flex-wrap: initial;
        }
        .input-box {
          label {
            display: block;
            width: 221px;
          }
          &:nth-child(even) {
            .input-container {
              padding-left: 55px;
            }
          }
        }
        @media screen and (max-width: 640px) {
          &.one-column-input {
            .input-container {
              display: unset;
              flex-wrap: initial;
              .input-box {
                .input-form {
                  input {
                    &::placeholder {
                      color: var(--color-line-01);
                    }
                  }
                }
                width: 100%;
              }
            }
          }
          .input-box {
            display: inline;
            &:nth-child(even) {
              .input-container {
                padding-left: 0;
              }
            }
          }
        }
      }
      .currency-sign-input-container {
        display: flex;
        position: relative;

        span {
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
        }
      }
    }
  }

  .grid-column {
    &.three {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      @media screen and (max-width: 640px) {
        grid-template-columns: repeat(1, 1fr);
      }

      .input-form {
        width: 100%;
        margin: 0;
      }
    }

    &.m-l-r-auto {
      margin-left: auto;
      margin-right: auto;
      width: calc(100% - 32px);

      @media screen and (max-width: 640px) {
        width: 100%;
      }
    }
  }

  .c-input-width {
    width: 669px !important;
  }

  .c-input-width-2 {
    width: 372px !important;
  }
  .c-select-width {
    width: 444px;
  }

  .signature-canvas {
    .canvas-wrapper {
      width: 100%;
      position: relative;
      margin-bottom: 51px;
      background-color: var(--color-background-04);

      .clear-btn {
        position: absolute;
        bottom: 16px;
        right: 16px;
        border-radius: 23px;
        border: none;
        width: 103px;
        background-color: var(--color-background-01);
        padding: 7px 20px;

        img {
          margin-right: 6px;
          vertical-align: middle;
          display: inline-block;
          margin-top: -2px;
          margin-bottom: 4px;
        }
      }
    }
    .modal {
      &:not(.popup) {
        .modal-body {
          padding: 0;
        }
      }

      .modal-body_content {
        padding: 30px;
        overflow: hidden;
      }

      .H-04 {
        text-align: left;
      }
    }
  }

  .signature-canvas-img {
    width: 572px;
    margin: 0 auto 50px;

    img {
      margin: 0 auto;
      display: block;
      width: 100%;
    }
  }

  &.cpad_wrapper2 {
    width: 960px !important;
    .font-w-normal {
      font-weight: normal !important;
    }
    .cpad-tables {
      margin: 38px 16px 20px;
      width: calc(100% - 32px);
      table {
        thead {
          tr {
            &:first-child {
              th {
                background-color: var(--color-primary);
                color: var(--color-white);
                &:last-child {
                  background-color: var(--color-accent-03);
                }
              }
            }
            th {
              background-color: var(--color-line-04);
              font-weight: bold;
            }
          }
          &.custom-thead {
            tr {
              &:nth-child(2) {
                th {
                  padding: 5px 10px;
                }
              }
            }
          }
          th {
            border: 1px solid var(--color-line-05);
            padding: 7px 10px;
            text-align: center;
            font-weight: normal;
          }
        }

        td {
          border: 1px solid var(--color-line-05);
          text-align: center;
          padding: 13px 10px;

          .side-td {
            display: flex;
            justify-content: space-between;

            .upward {
              transform: rotate(180deg);
            }
            .icon {
              cursor: pointer;
            }
          }
          .txt-small {
            span {
              font-size: 10px;
            }
          }

          .currency-sign {
            font-size: 10px !important;
          }
        }
        .no-border {
          border: none;
          background-color: transparent !important;
        }
        tbody {
          &.left-align-text {
            tr {
              &:last-child {
                td {
                  &:first-child {
                    font-size: 14px;
                  }

                  font-weight: bold;
                  font-size: 16px;
                }
              }
              td {
                &:first-child {
                  text-align: left;
                  font-weight: bold;
                }
              }
            }
            td {
              text-align: right;
            }
          }
          &.no-border-left-fc {
            tr {
              td {
                &:first-child {
                  border-left: none;
                }
              }
            }
          }
          .collapse-row {
            background-color: var(--color-background-04);
            .grid-section {
              width: 38%;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 10px 50px;
              .D-01 {
                text-align: left;
                display: flex;
                justify-content: space-between;
              }
            }
            .vertical-align-txt {
              vertical-align: top;
            }
          }
          &.double-border {
            tr {
              &:last-child {
                td {
                  border-top: solid 2px var(--color-line-01);
                  padding: 14px 10px;
                }
              }
            }
          }
          &.double-border-last-two {
            tr {
              &:nth-last-child(2) {
                td {
                  border-top: solid 2px var(--color-line-01);
                  padding: 14px 10px;
                  font-weight: bold;
                }
              }
            }
          }
          &.special-border {
            border: solid var(--color-primary) 1px !important;
            tr {
              td {
                &:first-child {
                  border-top: solid var(--color-primary) 1px !important;
                }
              }
            }
          }
        }

        &.border-style-1 {
          thead {
            border-right: solid var(--color-primary) 1px;
            tr {
              th {
                &:last-child {
                  border-right: none;
                }
              }
              &:nth-child(2) {
                th {
                  &:first-child {
                    border-left: solid var(--color-primary) 1px;
                  }
                }
              }
            }
          }
          tbody,
          tfoot {
            border: solid var(--color-line-01) 1px;
            border-top: none;
            tr {
              &:first-child {
                td {
                  border-top: solid var(--color-line-01) 2px;
                }
              }
              td {
                &:first-child {
                  border-left: none;
                }
                &:last-child {
                  border-right: none;
                }
              }
              &:last-child {
                td {
                  border-bottom: none;
                  font-size: 14px !important;
                }
              }
            }
          }
        }

        &.table-style-1 {
          thead {
            tr {
              &:first-child {
                th {
                  text-align: center;
                  &.no-bg-color {
                    background-color: transparent;
                  }
                  &:last-child {
                    background-color: var(--color-primary);
                  }
                }
              }
            }
            th {
              text-align: left;
            }
          }
          .left-align-text {
            td {
              text-align: left !important;
              font-weight: normal !important;
            }
          }
          .v-align-top {
            vertical-align: top;
          }
          tbody {
            tr {
              td {
                &:first-child {
                  background-color: var(--color-primary);
                  color: var(--color-white);
                }
              }
            }
            &.right-align-text {
              tr {
                td {
                  &:first-child {
                    text-align: left;
                  }
                  text-align: right;
                }
              }
            }
          }

          th,
          td {
            &.bg-color-06 {
              background-color: var(--color-primary) !important;
              color: var(--color-white) !important;
            }
          }
        }
      }
      &.normal {
        margin: 5px 16px 48px;
        table {
          thead {
            tr {
              th {
                background-color: var(--color-accent-03);
                color: var(--color-white);
              }
              &:first-child {
                color: red;
                th {
                  background-color: var(--color-primary);
                }
              }
            }
          }
          tbody {
            tr {
              &:last-child {
                td {
                  &:not(:first-child) {
                    font-weight: 500;
                  }
                }
              }
            }
          }
          tfoot {
            tr {
              td {
                text-align: right;
                font-weight: 700;
              }
            }
          }
        }
      }
      &.special {
        table {
          tbody {
            tr {
              td {
                font-weight: bold;
                background: var(--color-primary);
                color: var(--color-white);
              }
              td:nth-last-child(-n + 2) {
                font-weight: inherit;
                background: initial;
                color: inherit;
              }
            }
          }
        }
      }
      @media screen and (max-width: 640px) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        &.normal {
          margin: 5px 0 32px;
        }

        &.vertical-sp-view {
          table {
            tbody {
              tr {
                td {
                  padding: 7px 5px;
                  width: 60%;
                  text-align: center;
                  &:first-child {
                    width: 40%;
                  }
                  &:last-child {
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
      .txt-note {
        span {
          font-size: 12px;
        }
      }
    }
    .responsive-table {
      margin: 5px 16px 48px;
      width: calc(100% - 32px);
      table {
        th {
          background-color: var(--color-primary);
          color: var(--color-white);
          font-weight: 700;
          border: 1px solid var(--color-line-05);
          padding: 7px 10px;
          text-align: center;
        }
        td {
          border: 1px solid var(--color-line-05);
          padding: 13px 10px;
          &:first-child {
            width: 170px;
          }
        }
      }

      @media screen and (max-width: 640px) {
        margin: 0;
        width: 100%;
        table {
          margin: unset;
          width: 100%;
        }

        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        thead {
          tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
          }
        }
        tr {
          margin-bottom: 15px;
          border: 1px solid #ccc;
          td {
            display: flex;
            flex-wrap: nowrap;
            background-color: var(--color-white);
            border: none !important;
            border-bottom: 1px solid #ccc !important;
            padding: unset !important;
            span {
              width: calc(100% - 40%);
              padding: 7px 5px;
            }
            &::before {
              padding: 0 3px 0;
              width: 40%;
              text-align: center;
              background-color: var(--color-primary);
              justify-content: center;
              align-items: center;
              display: inline-flex;
              color: #fff;
            }

            &:last-child {
              border-bottom: none !important;
            }
            &:first-child {
              width: unset !important;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .txt_right {
          text-align: unset !important;
        }

        td:nth-of-type(1):before {
          content: attr(data-before);
        }
        td:nth-of-type(2):before {
          content: attr(data-before);
        }
        td:nth-of-type(3):before {
          content: attr(data-before);
        }
        td:nth-of-type(4):before {
          content: attr(data-before);
        }
        td:nth-of-type(5):before {
          content: attr(data-before);
        }
        td:nth-of-type(6):before {
          content: attr(data-before);
        }
      }
    }
    .vertical-sp-view {
      table {
        tbody {
          tr {
            td {
              width: 72%;
              text-align: left;
              &:first-child {
                width: 28%;
              }
            }
          }
        }
      }
    }
    .chart-section {
      display: flex;
      margin-bottom: 16px;
      margin-left: 16px;
      margin-right: 16px;
      width: calc(100% - 32px);

      &.graph-2 {
        width: 100%;
        gap: 15px;

        h2 {
          font-size: 16px;
          margin-bottom: 20px;
          padding-top: 7px;
        }
        .graph-legends {
          gap: 16px !important;
        }
        .toggle-section {
          .switch-toggle {
            display: flex;
            background-color: var(--color-background-02);
            border-radius: 18px;
            padding: 5px 20px 5px 10px;
            gap: 13px;
            color: var(--color-line-01);
            font-size: 12px;

            &.on {
              padding: 5px 10px 5px 20px;
            }

            .show {
              display: block;
            }
            .hide {
              display: none;
            }

            dd,
            dt {
              display: flex;
              align-items: center;
              cursor: pointer;
            }
            .active {
              color: var(--color-white);
              background-color: var(--color-primary);
              padding: 4px 15px;
              border-radius: 16px;
              font-weight: bold;
              cursor: pointer;
            }
          }
        }
        .right-container {
          padding: 15px 30px 30px;
          width: 50%;
          border: 1px solid var(--color-line-01);
          height: 389px;

          @media screen and (max-width: 640px) {
            width: 100%;
          }
        }
      }
      @media screen and (max-width: 640px) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        display: block;
      }
      .card-estimate-section {
        .card {
          &.card-scroll {
            height: 350px;
            overflow: scroll;
            width: 220px;

            .estimate_wrapper-display {
              width: 213px;
            }
          }
          .card-body {
            padding: 0;
          }
        }
      }
      .estimate_wrapper-display {
        margin: 0;
        box-shadow: none;
        width: 100%;
      }
      .right-container {
        padding-left: 24px;
        @media screen and (max-width: 640px) {
          padding-left: 0;
          margin-bottom: 20px;
        }
        .fee-section {
          margin-bottom: 16px;
          padding: 13px 16px;
          display: flex;
          border-radius: 10px;
          border: 1px solid var(--color-line-01);
          .monthly-fee-container {
            width: 66%;
            border-right: 1px solid var(--color-line-01);
            padding-right: 20px;
          }
          .timeline-container {
            display: flex;
            gap: 8px;
            .timeline-item {
              width: 33.3%;

              h3 {
                background: var(--color-line-01);
                padding: 8px 12px;
                text-align: center;
                font-size: 14px;
                border-radius: 8px;
              }
              .section-list {
                padding: 6px 8px;
                li {
                  border-bottom: 1px solid var(--color-line-01);
                  margin-bottom: 8px;
                  padding-bottom: 6px;

                  &:last-child {
                    border-bottom: none;
                  }
                  dl {
                    display: flex;
                    justify-content: space-between;
                    dt {
                      font-weight: 500;
                    }
                  }
                }
              }

              .D-01 {
                background-color: var(--color-line-01);
                text-align: center;
                border-radius: 10px;
                padding: 7px 7px;
                span {
                  font-size: 11px;
                }
              }
            }
          }
          .total-cost-container {
            width: 34%;
            padding-left: 20px;
            .timeline-container {
              margin-bottom: 10px;
              .timeline-item {
                width: 80px;
                text-align: right;

                h3 {
                  padding: 8px 0;
                }
                &:first-child {
                  width: 37px;

                  h3 {
                    background-color: transparent;
                    padding: 0;
                  }
                }

                .section-list {
                  padding: 6px 0;
                  &:first-child {
                    padding-top: 44px;
                  }
                }
              }
            }

            .diff-container {
              text-align: center;
              padding: 6px 8px 10px;
              background-color: var(--color-line-01);
              border-radius: 10px;

              h3 {
                margin-bottom: 11px;
              }

              .D-01 {
                padding-bottom: 15px;
                border-bottom: 1px solid;

                span {
                  font-size: 21px;
                }

                &:last-child {
                  padding-top: 13px;
                  padding-bottom: 0;
                  border-bottom: none;
                }
              }
            }
          }
        }
        .graph-top-section {
          display: flex;
          justify-content: space-between;

          .M-01 {
            .input-form {
              margin: 0;
            }
          }
        }
        .graph-legends {
          display: flex;
          gap: 40px;
          margin-bottom: 20px;

          .D-01 {
            display: flex;
            &:nth-child(2) {
              .chart-legend {
                background-color: var(--color-line-07);
              }
            }
            &:nth-child(3) {
              .chart-legend {
                background-color: var(--color-line-01);
              }
            }

            .chart-legend {
              width: 16px;
              height: 16px;
              background-color: var(--color-line-06);
              border-radius: 4px;
              margin-top: 2px;
            }
            p {
              padding-left: 8px;
            }
          }
        }
        .graph-container {
          display: flex;

          .bar-graph-item {
            width: 174px;

            &.two {
              width: 100%;
              overflow: hidden;
              display: flex;
              justify-content: space-between;
            }

            p {
              border-bottom: 1px solid var(--color-line-01);
              text-align: center;
              margin: 0 10px 0;
            }
          }
        }

        .graph-top-section {
          &.cus-width {
            width: 78%;
          }
        }
      }
    }
    .graph-remark {
      margin: 0 16px 38px 16px;
      font-size: 12px;
    }

    .summary-section {
      margin-bottom: 37px !important;
      &.mx-16 {
        margin-left: 16px;
        margin-right: 16px;
        width: calc(100% - 32px);
      }
      @media screen and (max-width: 640px) {
        width: 100%;
      }

      ul {
        li {
          border-bottom: solid 1px var(--color-line-01);
          padding: 0 5px 6px;
          margin-bottom: 8px;

          &:nth-child(n + 3) {
            padding-left: 0;
            .detail {
              padding-left: 5px;
            }
          }

          .detail {
            display: flex;
            justify-content: space-between;
          }
        }
      }
      .txt-small {
        font-size: 12px;
      }
    }

    .agency-info-section {
      width: 816px;
      margin: 0 auto;
      margin-bottom: 34px !important;

      &.mx-16 {
        margin-left: 16px;
        margin-right: 16px;
        width: calc(100% - 32px);
      }

      @media screen and (max-width: 640px) {
        width: 100%;
      }
      @media screen and (max-width: 400px) {
        .input-container {
          flex-direction: column;
          input,
          select,
          .pull-down {
            width: 100% !important;
          }
        }
      }

      .grid-column {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 34px;
        margin-bottom: 32px;
        @media screen and (max-width: 640px) {
          width: 100%;
          grid-template-columns: repeat(1, 1fr);
        }

        &.side-label-input {
          .input-box {
            &:nth-child(even) {
              .input-container {
                padding-left: 0 !important;
              }
            }
            .input-form {
              margin-left: 0;
              margin-right: 0;
              width: 100%;
              margin-bottom: 15px;
              .input-container {
                justify-content: space-between;
                label {
                  width: unset !important;
                  font-size: 14px;
                  font-weight: normal;
                  @extend %fontYuGothic;
                }
                input {
                  width: 300px;
                  padding: 12px 15px;
                  text-align: left !important;
                  color: var(--color-black) !important;
                  @extend %fontYuGothic;
                }
                .pull-down {
                  width: 300px;
                  text-align: left !important;
                  color: var(--color-black) !important;
                  @extend %fontYuGothic;
                }
                .calendar {
                  > input {
                    padding: 12px 15px 12px 50px;
                  }
                }
              }
            }
          }
        }
        &.custom-width {
          .input-box {
            .input-form {
              .input-container {
                label {
                  width: 40% !important;
                }
                select {
                  width: 258px;
                }
                .calendar {
                  input {
                    width: 280px;
                  }
                }
              }
            }
          }
        }
      }
      .print-btn-img {
        width: 100%;
        text-align: right;
        .btn {
          &_icon {
            position: relative;
            min-width: 120px !important;
          }
        }
      }
    }

    .agency-info-section-confirmation {
      display: block;
      margin: 0 0 50px;

      .input-box {
        .input-form {
          margin-bottom: 16px;
          .input-container {
            width: 100%;
            @media screen and (max-width: 640px) {
              display: block;
            }
            > label {
              display: block;
              max-width: 175px;
              font-weight: normal;
            }
            > input {
              width: 320px;
            }
            > .pull-down {
              > select {
                width: 444px;
              }
            }
          }
        }
      }
    }

    .hearing-other-option-section {
      gap: 20px;
    }

    .register-installation-address-section {
      .grid-column-two {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        input {
          width: 400px;
        }

        @media screen and (max-width: 640px) {
          width: 100%;
          grid-template-columns: repeat(1, 1fr);
        }
      }

      input {
        width: 100%;
      }
    }

    .chart-bottom-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &.center {
        justify-content: center;
        gap: 0 24px;
      }
    }

    .changes-section {
      padding: 0 20px;

      .cpad-tables {
        margin-top: 25px;
        margin-bottom: 60px;
        &:first-child {
          margin-bottom: 35px;
        }

        thead {
          tr {
            &:last-child {
              th {
                padding: 12px 10px;
              }
            }
          }
        }
      }
    }
  }
}
