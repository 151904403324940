@import 'mixins';

.btn_wrapper {
  display: inline-block;
  .btn {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "Noto Sans JP",sans-serif;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    &:not(.btn_txt) {
      margin-bottom: 24px;
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
    &_logout {
      font-weight: 500;
      padding: 3px 10px;
      border-radius: 13px;
      color: var(--color-primary-text);
      font-size: 12px;
      background-color: var(--color-background-02);
      border: 1px solid var(--color-background-02);
      &:hover {
        background-color: var(--color-background-01);
      }
    }
    &_popup {
      border: none;
      color: var(--color-accent-03);
      //font-size: 16px;
      padding: 0;
      text-decoration: underline;
      font-weight: normal;
      text-align: left;
    }
  }

  &.primary {
    &.disabled {
      .btn {
        background-color: var(--color-line-01);
        border-color: var(--color-line-01);
        &:hover {
          color: var(--color-white);
        }
      }
    }
    .btn {
      background-color: var(--color-primary);
      border: 1px solid var(--color-primary);
      color: var(--color-white);
      //font-size: 16px;
      padding: 11px 50px;
      &_white {
        border-radius: 24px;
        min-width: 200px;
        background-color: var(--color-background-01);
        border: 1px solid var(--color-primary);
        color: var(--color-primary-text);
        &:hover {
          opacity: 0.6;
        }
      }
      &_default {
        border-radius: 24px;
        min-width: 200px;
        
        &.inline-button {
          margin-bottom: 0;
          margin-top: 30px;
          @media screen and (max-width: 640px) {
            margin-top: -20px;
            margin-bottom: 24px;
          }
        }
        &.arrow-right {
          @extend %flexbox-center;
          min-width: 235px;
          padding: 11px 10px;
          &::before {
            display: none;
          }
          &:after {
            margin-left: 20px;
            margin-top: 2px;
          }
          img {
            margin-left: 20px;
          }
          &:hover {
            img {
              filter: invert(73%) sepia(29%) saturate(5754%) hue-rotate(359deg) brightness(103%) contrast(106%);
            }
          }
        }
      }

      &_icon {
        border-radius: 29px;
        min-width: 138px;
        position: relative;
        padding: 9px 15px 9px 10px;
        //font-size: 16px;
        &:before {
          //font-size: 14px;
          margin-right: 10px;
          vertical-align: middle;
          display: inline-block;
          margin-top: -7px;
        }
        img {
          margin-right: 10px;
          vertical-align: middle;
          display: inline-block;
          margin-top: -10px;
        }
        &:not(.disabled):hover {
          img {
            filter: brightness(0);
          }
        }
      }
      &:hover {
        background-color: var(--color-background-01);
        color: var(--color-primary-text);
      }

      &.disabled {
        background-color: var(--color-line-01);
        border-color: var(--color-line-01);
        &:hover {
          color: var(--color-white);
        }
      }

      &_small {
        border-radius: 5px;
        font-size: 12px;
        min-width: 72px;
        padding: 9px 5px;

        &:hover {
          background-color: var(--color-primary);
          border: 1px solid var(--color-primary);
          color: var(--color-white);
        }
      }

      &_medium {
        border-radius: 24px;
        min-width: 118px;
        padding: 11px 10px;
      }
    }
  }

  &.big {
    .btn {
      min-width: 328px;
      width: 100%;
    }
  }

  &.secondary {
    &.disabled {
      .btn {
        img {
          filter: invert(58%) sepia(4%) saturate(6%) hue-rotate(356deg) brightness(85%) contrast(86%);
        }
        border-color: var(--color-line-01);
        color: var(--color-line-01);
        opacity: 1;
      }
    }
    .btn {
      background-color: var(--color-background-01);
      border: 1px solid var(--color-primary);
      color: var(--color-primary-text);
      //font-size: 14px;
      padding: 11px 20px;
      min-width: 138px;
      border-radius: 29px;
      &_icon {
        position: relative;
        padding: 9px 15px 9px 10px;
        &:before {
          //font-size: 14px;
          margin-right: 10px;
          vertical-align: middle;
          display: inline-block;
          margin-top: -2px;
        }
        img {
          margin-right: 10px;
          vertical-align: middle;
          display: inline-block;
          margin-top: -2px;
        }
        &.disabled {
          img {
            filter: invert(58%) sepia(4%) saturate(6%) hue-rotate(356deg) brightness(85%) contrast(86%);
          }
        }
      }
      &:hover {
        opacity: 0.6;
      }

      &.disabled {
        border-color: var(--color-line-01);
        color: var(--color-line-01);
        opacity: 1;
      }
    }
  }

  &.consent {
    max-width: 455px;
    margin: auto;
    button {
      position: relative;
      border-radius: 50px;
      border: 2px solid var(--color-primary);
      color: var(--color-primary-text);
      min-width: 454px;
      max-width: 100%;
      //font-size: 14px;
      padding: 7px 10px;
      display: block;
      text-align: center;
      vertical-align: middle;
      background: var(--color-background-01);
      font-weight: 700;

      &.disabled {
        cursor: none;
        pointer-events: none;
      }
      .input-container {
        justify-content: center;
        align-items: center;
        span {
          margin-right: 7px;
        }
        input[type='checkbox'] {
          + label {
            padding: 5px 0 5px 30px;
            margin-left: 10px;
            max-width: calc(100% - 65px);
            text-align: left;
            &:before {
              top: calc(50% - 8px);
            }
            &:after {
              top: calc(50% - 12px);
            }
          }
        }
      }
      &:not(.disabled):hover {
        opacity: 0.6;
      }
      &.disabled {
        border: 2px solid var(--color-line-01);
        color: var(--color-line-01);
        span {
          background-color: var(--color-line-01);
        }
        .input-container {
          input {
            &[type='checkbox'] {
              & + label {
                line-height: 22px;
                &:before {
                  border: 1px solid var(--color-line-01);
                }
              }
              &:checked {
                & + label {
                  &:before {
                    content: '';
                    background: var(--color-line-01);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.disabled {
    cursor: auto;
    pointer-events: none;
  }

  &.fix {
    position: fixed;
    background: rgba(35, 24, 21, 0.4);
    padding: 24px 15px;
    left: 0;
    z-index: 9;
    bottom: 0;
    width: 100%;
    text-align: center;
    &.disabled {
      border: 1px solid var(--color-line-01);
      .btn {
        background-color: var(--color-white);
        color: var(--color-line-01);
      }
    }
    .btn {
      margin: 0;
      width: 815px;
      max-width: 100%;
      border-radius: 5px;
      border: 1px solid var(--color-primary);
      background-color: var(--color-white);
      color: var(--color-primary-text);
      &:hover {
        opacity: 0.5;
      }
    }
  }
  @media screen and (max-width: 640px) {
    .btn {
      font-size: 14px;
      &_logout {
        font-size: 12px;
      }
    }
    &.big {
      .btn {
        min-width: auto;
      }
    }
    &.primary,
    &.secondary {
      .btn {
        margin: 5px 0 0;
        padding: 11px 10px;
        &_icon {
          min-width: 320px;
          max-width: 100%;
        }
      }
    }
    &.secondary {
      .btn {
        padding: 11px 20px;
        &_default {
          min-width: 104px;
          max-width: 100%;
        }
      }
    }
    &.consent {
      margin: 5px auto;
      button {
        min-width: 328px;
      }
    }
  }
  &.mobile_btn_full-width {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
}
