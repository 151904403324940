@import "mixins";

.breadcrumb{
  background: none;
  @extend %component;
  @extend %flexbox-left;
  margin: 24px auto;
  ol{
    @extend %flexbox-center;
    li{
      &.breadcrumb-item{
        display: flex;
        padding-left: .5rem;
        align-items: center;
        .navigation-title{
          font-size: 12px;
          padding-right: .5rem;
        }
        a{
          color: var(--color-secondary-text);
          font-size: 12px;
        }
        &::before{
          display: none;
        }
        &.last{
          .separator{
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    ol{
      justify-content: left;
    }
  }
}